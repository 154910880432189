// client\src\app\features\UserReport\DetailDrawer\index.tsx
import Drawer from '@mui/material/Drawer'
import { SetStateAction, useEffect, useState } from "react";
import Axios from 'axios';
import useGetState from '../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../constants/apiConstants';
import { Container, Typography } from '@mui/material';
import { PowerBIEmbed } from 'powerbi-client-react';
import Button from '@mui/material/Button';
import { Embed } from "powerbi-client";
import RightDrawer from '../../../atoms/tempDrawer/indexAlt';
import filterIcon from '../../../../assets/images/filter.svg';
import CPPReport from '../CppReport/index';
import CPPFilter from '../CppReport/CppFilter/index';
import './Index.scss';
import { trackErrorsInNewRelic } from '../../../utils/newRelicFuncations';

type Props = {
  status: any;
  handlerOpen: any;
  handlerClose: any;
  data?: any;
  drawerHeader?: any;
  block?: boolean;
  type?: string;
  editFn?: Function;
  deleteFn?: Function;
  index?: number;
  groudID?: string;
  reportID?: string;
  reportURL?: string;
  base?: string;
  reportConfig?: any;
};

export default function PowerBIViewDrawer(props: Props) {
  const {
    status,
    handlerClose,
    data,
    type,
  } = props;
  let defaultStartDate = new Date();
  defaultStartDate = new Date(defaultStartDate.setDate(defaultStartDate.getDate() - 7));
  const [reportRender, setReportRender] = useState(false);
  const [embedReport, setEmbedReport] = useState<Embed>();
  const [temperatureFilters, setTemperatureFilters] = useState<any[]>([]);
  const [allFilter, setAllFilters] = useState<any[]>([]);
  const [allFilterValue, setAllFilterValue] = useState<any>({})
  const [bKey, setBKey] = useState('');
  const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
  const [drawerHeader, setDrawerHeader] = useState('Apply Filters');
  const [initialValues, setInitialValues] = useState({
    plantInputValue: '',
    machineNameInputValue: '',
    batchInputValue: '',
    recipeInputValue: '',
    startDate: defaultStartDate,
    endDate: new Date()
  });

  const [tempStartDate, setTempStartDate] = useState(defaultStartDate);
  const [tempEndDate, setTempEndDate] = useState(new Date());
  const [plantKey, setPlantKey] = useState('');
  const [machineNameKey, setMachineNameKey] = useState('');
  const [selelctedMachine, setSelelctedMachine] = useState([]);
  const [recipeKey, setRecipeKey] = useState('');
  const [productKey, setProductKey] = useState('');

  const state = useGetState();
  const token = state?.[STORE_KEYS.USER_DTL].token;
  const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;

  const handlerestFilter = ()=>{
    setPlantKey('')
    setMachineNameKey("")
    setRecipeKey("")
    setSelelctedMachine([])
    setProductKey("")
    setTempStartDate(defaultStartDate)
    setTempEndDate(new Date())
  }
  
  const handleClose = () => {
    setTemperatureFilters([]);
    setAllFilters([]);
    setAllFilterValue({})
    handlerestFilter()
    handlerClose();
  };
  
  const eventHandlersMap = new Map([
    ["loaded", () => { }],
    [
      "rendered", () => { setReportRender(true); }], //Event handler for err
    [
      "error", (event: any) => {
        if (event) {
        }
      },
    ],
    ["saved", () => { }],
  ]);
  useEffect(()=>{
    setSelelctedMachine([])
},[plantKey])
  const handleSubmit = async (plantKey:any, machineNameKey:any, tempStartDate:any, tempEndDate:any, productKey:any, recipeKey:any) => {
    setAllFilterValue({plantKey, machineNameKey, tempStartDate, tempEndDate, productKey, recipeKey  })
  }

  const powerBIReportsView = () => (
    <Container className={'BiReportsContainer'}>
      <PowerBIEmbed
        embedConfig={props.reportConfig}
        eventHandlers={eventHandlersMap}
        cssClassName={"aggregate-report-style-class"}
        getEmbeddedComponent={(embedObject: Embed) => {
          setEmbedReport(embedObject);
        }}
      />
    </Container>
  );

  const FeatureView = () => (
    <Container className={'FeatureDrawerDiv cppDiv'}>
      <div className="baseDiv">
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <div style={{ flex: '70%', display: 'flex', alignItems: 'center' }}>
            <div className="headinglabel" style={{ marginTop: '10px', paddingLeft: '40px' }}>
              CPP Analysis Report
            </div>
            <div
              style={{
                flex: '20%',
                marginLeft: '20px',
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
              className="dashboardDropdownDiv">
                <div className='tableToolbarCPP'>
<div className="filter" onClick={() => setCreateDrawerOpen(true)} style={{ display: 'flex', marginLeft: '10px' }}>
  <img src={filterIcon} />
  <span>Filter</span>
</div>
</div>
              {/* <Button variant="outlined" color="primary" className="machineDetailButton" onClick={() => setCreateDrawerOpen(true)}>
               Filter
              </Button> */}
            </div>
          </div>
        </div>
      </div>
      <RightDrawer
        handlerOpen={() => { }}
        handlerClose={() => {setCreateDrawerOpen(false);setAllFilters([]); setTemperatureFilters([])}}
        status={createDrawerOpen}
        drawerHeader={`${drawerHeader}`}
        children={
          <CPPFilter
            drawerClose={() => {setCreateDrawerOpen(false); setAllFilters([]); setTemperatureFilters([])}}
            handleSubmit={handleSubmit}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            setTemperatureFilters={setTemperatureFilters}
            setAllFilters={setAllFilters}
            tempStartDate={tempStartDate}
            setTempStartDate={setTempStartDate}
            tempEndDate={tempEndDate}
            setTempEndDate={setTempEndDate}
            plantKey={plantKey}
            setPlantKey={setPlantKey}
            machineNameKey={machineNameKey}
            setMachineNameKey={setMachineNameKey}
            setRecipeKey={setRecipeKey}
            recipeKey={recipeKey}
            selelctedMachine={selelctedMachine}
            setSelelctedMachine={setSelelctedMachine}
            productKey={productKey}
            setProductKey={setProductKey}
            allFilters={allFilterValue}
            open={createDrawerOpen}
          />
        }
      />
      <CPPReport 
        allFilters={allFilterValue}
        temperatureFilters={temperatureFilters}
        bKey={bKey}
        
      />
    </Container>
  );

  return (
    <div>
      <Drawer
        anchor={'right'}
        open={status}
        onClose={handleClose}
        style={{ zIndex: '9999' }}
        className={'powerBIDrawerDiv'}>
        <div
          onClick={handleClose}
          style={{
            border: '1px solid #5D97F6',
            borderRadius: '50px',
            height: '25px',
            width: '25px',
            position: 'absolute',
            left: '20px',
            top: type === 'Role' ? '30px' : type === 'Feature' ? '17px' : '20px',
            cursor: 'pointer'
          }}
        >
          <span style={{ position: 'relative', fontSize: '12px', color: '#5D97F6', left: '35%', top: '-3%' }}>
            X
          </span>
        </div>
        {status ? props.data?.ReportType == "PowerBI" ? (props.reportConfig?.accessToken && powerBIReportsView()) : FeatureView() : null}
      </Drawer>
    </div>
  );
}