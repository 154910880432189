import { Paper, Typography, IconButton } from '@mui/material';
import { Formik, Form } from 'formik';
import { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import TextInput from '../../../../atoms/TextInput/index';
import Label from '../../../../atoms/Label/index';
import Button from '../../../../atoms/Button/index';
import { CREATEPWD_YUPSCHEMA } from './schema';
import VisibilityIcon from '../../../../../assets/visibilityIcon.svg';
import VisibilityOffIcon from '../../../../../assets/visibilityOffIcon.svg';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { useDispatch, useSelector } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import { createStructuredSelector } from 'reselect';
import { acgSelector } from '../../../../store/selector';
import SnackbarAlert from '../../../../atoms/Snackbar';

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};

type ComponentProps = { data: any };
const CreatePassword = (props: ComponentProps) => {
    const { data } = props;
    const [validationSchema, setValidationSchema] = useState({});
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [showPassword, setShowPassword] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const handleClick = () => {
        setShowPassword((prev) => !prev);
    };
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const acgStateSelector = createStructuredSelector({
        acgSlice: acgSelector()
    });
    const { acgSlice: state } = useSelector(acgStateSelector);
    const dispatch = useDispatch();
    const handleClickConfirm = () => {
        setShowConfirmPassword((prev) => !prev);
    };
    const [initialValues] = useState({
        newPwd: '',
        confirmPwd: ''
    });
    const formRef = useRef<any>();

    useEffect(() => {
        setValidationSchema(Yup.object().shape(CREATEPWD_YUPSCHEMA));
    }, []);

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    const handleSubmit = async () => {
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.CHANGEPWD,
                requestType: 'POST',
                reqObj: {
                    email: state[STORE_KEYS.API_POST_VERIFY_RESET_OTP]?.email,
                    newPassword: formRef?.current?.values?.newPwd,
                    otp: state[STORE_KEYS.API_POST_VERIFY_RESET_OTP]?.emailForOTP
                }
            },
            uniqueScreenIdentifier: { pwdCreated: true },
            storeKey: STORE_KEYS.CHANGE_PWD
        };
        dispatch(executeACGAction(bundle));
    };

    useEffect(() => {
        if (state?.changePwd?.pwdCreated) {
            if (state?.changePwd?.statusCode === 200) {
                dispatch(
                    updateScreenIdentifiers({
                        storeKey: STORE_KEYS.CHANGE_PWD,
                        uniqueScreenIdentifier: {
                            pwdCreated: false
                        }
                    })
                );
                data?.setTemplateType('passwordSuccess');
            }
        }
    }, [state?.changePwd?.pwdCreated]);
    return (
        <Paper elevation={2} className="loginPaper">
            <SnackbarAlert className="login-snackbar" options={snackbarOptions} handleClose={closeSnackbar} />
            <div className="loginFormHeader">
                <Typography className="welcomeText">Create Password</Typography>
                <Typography className="loginText">Create your new password</Typography>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={() => {
                    handleSubmit();
                }}
                validateOnBlur={false}
                innerRef={formRef}
            >
                {(formikprops) => {
                    const { values, handleChange, handleBlur, isValid, errors, touched } = formikprops;
                    return (
                        <Form>
                            <Label label="New Password" />
                            <TextInput
                                variant="outlined"
                                maxLength={30}
                                autoFocus
                                fullWidth
                                type={showPassword ? 'text' : 'password'}
                                name="newPwd"
                                value={values.newPwd}
                                autoComplete="off"
                                formInput="textBoxDiv"
                                onChange={(e) => {
                                    setDisableSubmit(false);
                                    handleChange(e);
                                }}
                                endAdornment={
                                    <IconButton tabIndex={-1} className="password-eye" onClick={handleClick}>
                                        {showPassword ? (
                                            <img src={VisibilityIcon} alt="logo" />
                                        ) : (
                                            <img src={VisibilityOffIcon} alt="logo" />
                                        )}
                                    </IconButton>
                                }
                                onBlur={handleBlur}
                                placeholder="Enter new password"
                                helperText={errors.newPwd && touched.newPwd ? errors.newPwd : ''}
                            />
                            <Label className="password-class" label="Confirm Password" />
                            <TextInput
                                variant="outlined"
                                maxLength={50}
                                fullWidth
                                autoComplete="off"
                                type={showConfirmPassword ? 'text' : 'password'}
                                name="confirmPwd"
                                formInput="textBoxDiv"
                                onChange={(e) => {
                                    setDisableSubmit(false);
                                    handleChange(e);
                                }}
                                placeholder="Enter confirm password"
                                value={values.confirmPwd}
                                onBlur={handleBlur}
                                endAdornment={
                                    <IconButton tabIndex={-1} className="password-eye" onClick={handleClickConfirm}>
                                        {showConfirmPassword ? (
                                            <img src={VisibilityIcon} alt="logo" />
                                        ) : (
                                            <img src={VisibilityOffIcon} alt="logo" />
                                        )}
                                    </IconButton>
                                }
                                helperText={errors.confirmPwd && touched.confirmPwd ? errors.confirmPwd : ''}
                            />
                            <Button
                                formInput="buttonDiv"
                                fullWidth
                                name={data?.isForgotPwdPage ? 'Confirm' : 'Change Password'}
                                type="submit"
                                variant="contained"
                                disabled={disableSubmit || !isValid}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </Paper>
    );
};

export default CreatePassword;
