import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { FormControl, Select, MenuItem, FormHelperText, SelectProps } from '@material-ui/core';
import Label from '../Label/index';
import { FormControl, MenuItem, FormHelperText, Select, SelectChangeEvent, SelectProps } from '@mui/material';
import './index.scss';
import DownArrow from '../../../assets/down-arrow.svg';

type ItemsType = {
    name: string;
    type?: string;
    code: string;
    disabled?: boolean;
    canUnselect?: boolean;
};

interface DropDownProps {
    //     onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
    onChange: (event: SelectChangeEvent) => void;
    value?: any;
    items?: ItemsType[];
    name: string;
    label?: any;
    disabled?: boolean;
    style?: any;
    className?: string;
    type?: string;
    required?: boolean;
    helperText?: any;
    variant?: SelectProps['variant'];
    control?: Function;
    isIcon?: boolean;
    fullWidth?: boolean;
    placeHolder?: string;
    restricted?: boolean;
    canUnselect?: boolean;
}

const useStyles = makeStyles(() => ({
    formControl: {
        margin: 0,
        fullWidth: true,
        display: 'flex',
        wrap: 'nowrap',
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
        '& .MuiSelect-icon': {
            color: '#b1c2df'
        },

        '& .MuiInputLabel-formControl': {
            transform: 'translate(12px, 20px) scale(1)'
        },
        '& .MuiOutlinedInput-root': {
            height: '40px',
            backgroundColor: '#1D1E2C',
            borderRadius: '10px',
            borderWidth: '1px',
            borderColor: '#2B2D42',
            alignContent: 'center',
            justifyContent: 'center',
            width: '100%',
            '&.Mui-focused fieldset': {
                borderWidth: '1px',
                borderColor: '#2B2D42'
            }
        },
        '& .MuiFormHelperText-root': {
            color: '#F06868',
            fontSize: '11px',
            fontWeight: 600,
            letterSpacing: 0,
            marginBottom: '-20px',
            fontFamily: 'Montserrat'
        }
    },
    paper: {
        width: '100%',
        minWidth: '185px'
    },

    dropdownStyle: {
        color: '#b1c2df',
        fontSize: '12px',
        letterSpacing: '0',
        fontWeight: 500,
        lineHeight: '1',
        marginTop: '9px',
        background: '#1D1E2C'
    },
    disabledComponent: {
        opacity: 0.6
    },
    label: {
        height: '1',
        opacity: 0.5,
        color: '#2C333A',
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontWeight: 500,
        letterSpacing: 0,
        marginBottom: '12px'
    },
    optionStyle: {
        color: '#b1c2df',
        fontSize: '12px',
        letterSpacing: '0',
        fontWeight: 500,
        lineHeight: '2',
        padding: '3px 8px'
    },

    iconStart: {
        position: 'absolute',
        marginTop: '15px',
        marginLeft: '10px',
        zIndex: 9
    }
}));

export default function DropDown(props: DropDownProps) {
    const classes = useStyles();
    const {
        value,
        items=[],
        helperText,
        name,
        disabled,
        variant,
        required,
        isIcon,
        onChange,
        label,
        fullWidth,
        placeHolder,
        restricted,
        canUnselect = false,
        ...rest
    } = props;

    const DropDownIcon = (prop: any) => <img style={{ marginRight: '5px' }} src={DownArrow} {...prop} />;
    return (
        <div
            id="parent"
            className={`${classes.paper} ${disabled ? classes.disabledComponent : ''} acgDropdown`}
            style={props.style}
        >
            {label && <Label label={props.label} className={classes.label} />}
            <FormControl fullWidth className={`${classes.formControl}`} required={required} variant={variant}>
                <Select
                    style={helperText ? { border: '1px solid #F06868' } : null}
                    IconComponent={DropDownIcon}
                    defaultValue=""
                    fullWidth={fullWidth}
                    labelId="sub-category"
                    name={name}
                    displayEmpty
                    value={value??""}
                    onChange={onChange}
                    variant="outlined"
                    renderValue={(selected: any) => {
                        // console.log("selected?.length", )
                        if (!selected) {
                            return (
                                <span className={`${classes.optionStyle} ${classes.disabledComponent}`}>
                                    {placeHolder}
                                </span>
                            );
                        }
                        return (
                            <span className={classes.optionStyle}>
                                {items?.find((item) => item.code === selected)?.name || placeHolder}
                            </span>
                        );
                    }}
                    classes={{
                        disabled: disabled ? classes.disabledComponent : ''
                    }}
                    MenuProps={{
                        // classes: { paper: restricted ? classes.dropdownStyle + ' setHeight' : classes.dropdownStyle },
                        classes: { paper: classes.dropdownStyle + ' setHeight' },
                        // getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center'
                        }
                    }}
                    inputProps={{
                        disabled: disabled ? true : false
                    }}
                    control={props.control}
                    {...rest}
                >
                    {placeHolder && (
                        <MenuItem disabled={!canUnselect} value="">
                            <span className={classes.optionStyle}>{placeHolder}</span>
                        </MenuItem>
                    )}
                    {items?.map((item: ItemsType, i: number) => {
                        const itemId = i + 1;
                        return (
                            <MenuItem key={itemId} value={item.code} className={classes.optionStyle}>
                                <span className={classes.optionStyle}>{item?.name}</span>
                            </MenuItem>
                        );
                    })}
                </Select>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        </div>
    );
}
