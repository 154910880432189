import { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Axios from 'axios';
import FD from 'js-file-download';
import { useDispatch } from "react-redux";
import TextInput from '../../../../atoms/TextInput';
import Table from "../../../../atoms/StickyHeaderTable";
import { executeACGAction, reset, resetStoreKey, updateScreenIdentifiers } from "../../../../store/slice";
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import ACGButton from '../../../../atoms/Button'
import Attachments from '../../../../atoms/ExcelAttachments';
import useGetState from '../../../../utils/hooks/useGetState';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import DropDown from '../../../../atoms/Dropdown';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import FormControl from '@mui/material/FormControl';
import Label from '../../../../atoms/Label/index';
import Grid from '@mui/material/Grid';
import "../../ta.css"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function CreateForm(props: any) {
    const dispatch = useDispatch();
    const editing = props.componentLSKey !== '' ? true : false;
    const [sheetResult, setSheetResult] = useState<any>([]);
    const [assemblyName, setAssemblyName] = useState('');
    const [componentName, setComponentName] = useState('');
    const [lih, setLih] = useState<any>('');
    const [oh, setOh] = useState<any>('');
    const [selectedBoth, setSelectedBoth] = useState('');
    const [replacementAlert, setReplacementAlert] = useState<any>('');
    const [postDueReminder, setPostDueReminder] = useState<any>('');
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;

    useEffect(() => {
        if (props.componentLSKe !== '') {
            console.log("sssss",props.selectedRow)
            setAssemblyName(props.selectedRow?.AssemblyName);
            setComponentName(props.selectedRow?.ComponentName);
            setLih(props.selectedRow?.CalendarLifeInHours);
            setOh(props.selectedRow?.OperatingLifeInHours);
            setSelectedBoth(String(props.selectedRow?.AlertsBasisOperatingHours).toUpperCase());
            setReplacementAlert(props.selectedRow?.ReplacementAlertInHours);
            setPostDueReminder(props.selectedRow?.PostDueReminderInHours);
        }
    }, [props.componentLSKey])

    const reset = async () => {
        setComponentName('');
        setAssemblyName('');
        setSelectedBoth('');
        setLih('');
        setOh('');
        setReplacementAlert('');
        setPostDueReminder('');
    }

    const options = {
        AD_SUCCESS: {
            message: 'New component life cycle updated ',
            open: true,
            type: 'success'
        },
        AD_FAIL: {
            message: 'Component life cycle failed to update',
            open: true,
            type: 'remark'
        },
    };

    const createNewEntry = (type: string) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.POST_CLC_COMPONENT_LIFE_CYCLE_DETAILS,
                    reqObj: {
                        "LoggedUserKey": loggedInUser,
                        "MachineModelKey": props.selectedRow?.MachineModelKey,
                        "MacCompLifeCyJson": [{
                            "AssemblyName": assemblyName,
                            "ComponentName": componentName,
                            "CalendarLifeInHours": lih,
                            "OperatingLifeInHours": oh,
                            "AlertsBasisOperatingHours": selectedBoth,
                            "ReplacementAlertInHours": replacementAlert,
                            "PostDueReminderInHours": postDueReminder
                        }],
                        "AddEditActivity": type,
                        "NumberOfRows": 1
                    }
                },
                uniqueScreenIdentifier: { clcPostDetails: true },
                storeKey: STORE_KEYS.POST_CLC_COMPONENT_LIFE_CYCLE_DETAILS
            })
        );
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.POST_CLC_COMPONENT_LIFE_CYCLE_DETAILS]?.clcPostDetails) {
            const response = state?.[STORE_KEYS.POST_CLC_COMPONENT_LIFE_CYCLE_DETAILS]?.body?.data;
            if(response[0].Result === "Success"){
                props.setSnackbarOpts(options.AD_SUCCESS)
            } else {
                props.setSnackbarOpts({
                    message: `${response[0].ResultMessage}`,
                    open: true,
                    type: 'remark'
                })
            }
            props.drawerClose();
            props.getCLCList(props.filteredMachine)
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.POST_CLC_COMPONENT_LIFE_CYCLE_DETAILS,
                    uniqueScreenIdentifier: {
                        clcPostDetails: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.POST_CLC_COMPONENT_LIFE_CYCLE_DETAILS]?.clcPostDetails]);

    const yesDisabled = (assemblyName === "" || componentName === "" || lih === "" || oh === "" || replacementAlert === "" || postDueReminder==="" || selectedBoth === "") ? true : false;

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={() => { props.drawerClose(); reset() }}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { createNewEntry("Edit") }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={yesDisabled ? true : false}
                />
            </div>
        );
    };

    const handleChangeBoth = (e: any) => {
        setSelectedBoth(e.target.value);
    }
    return (
        <Container className="createReport">
            <Box>
                <div className='formwrap all-labelcls'>
                    <FormControl fullWidth>
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                <FormControl fullWidth>
                                    <Label label="Assembly Name *" className={'CMlabelDiv'} />
                                    <TextInput id="outlined-basic"
                                        fullWidth
                                        variant="outlined"
                                        sx={{ marginBottom: "20px", height: '20px' }}
                                        value={assemblyName}
                                        onChange={(e: any) => setAssemblyName(e.target.value)}
                                        placeholder='Please enter assembly name'
                                        disabled
                                    />
                                    <Label label="Component Name *" className={'CMlabelDiv'} />
                                    <TextInput id="outlined-basic"
                                        fullWidth
                                        variant="outlined"
                                        sx={{ marginBottom: "20px", height: '20px' }}
                                        value={componentName}
                                        onChange={(e: any) => setComponentName(e.target.value)}
                                        placeholder='Please enter component name'
                                        disabled
                                    />
                                    <Label label="Calendar Life In Hours *" className={'CMlabelDiv'} />
                                    <TextInput
                                        fullWidth

                                        id="outlined-basic"
                                        type={"number"}
                                        variant="outlined"
                                        sx={{ marginBottom: "20px", height: '20px' }}
                                        value={lih}
                                        onChange={(e: any) => {
                                            const value = e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 5)
                                            setLih(value.replace(/\D/g, ""));
                                        }}

                                        maxLength={5}
                                        // InputProps={{
                                        //     inputProps: {
                                        //         min: 0, maxLength: 5, max: 5, inputMode: "numeric",
                                        //         pattern: "[0-9]*"
                                        //     }
                                        // }}
                                        placeholder='Enter calendar life in hours *'
                                    />
                                    <Label label="Operating hours" className={'CMlabelDiv'} />
                                    <TextInput
                                        fullWidth

                                        id="outlined-basic"
                                        type={"number"}
                                        variant="outlined"
                                        sx={{ marginBottom: "20px", height: '20px' }}
                                        value={oh}
                                        onChange={(e: any) => {
                                            const value = e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 5)
                                            setOh(value.replace(/\D/g, ""));
                                        }}

                                        maxLength={5}
                                        // InputProps={{
                                        //     inputProps: {
                                        //         min: 0, maxLength: 5, max: 5, inputMode: "numeric",
                                        //         pattern: "[0-9]*"
                                        //     }
                                        // }}
                                        placeholder='Enter operating hours *'
                                    />
                                    <Label label="Alerts Basis Operating Hours *" className={'CMlabelDiv'} />
                                    <DropDown
                                        onChange={handleChangeBoth}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={[{ code: "Y", name: "Yes" }, { code: "N", name: "No" }]}
                                        placeHolder="Select Alerts Basis Operating Hours"
                                        value={selectedBoth}
                                    />
                                    <Label label="Replacement Alert in Hours *" className={'CMlabelDiv'} />
                                    <TextInput
                                        fullWidth

                                        id="outlined-basic"
                                        type={"number"}
                                        variant="outlined"
                                        sx={{ marginBottom: "20px", height: '20px' }}
                                        value={replacementAlert}
                                        onChange={(e: any) => {
                                            const value = e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 5)
                                            setReplacementAlert(value.replace(/\D/g, ""));
                                        }}

                                        maxLength={5}
                                        // InputProps={{
                                        //     inputProps: {
                                        //         min: 0, maxLength: 5, max: 5, inputMode: "numeric",
                                        //         pattern: "[0-9]*"
                                        //     }
                                        // }}
                                        placeholder='Enter replacement alert in hours'
                                    />
                                    <Label label="Post Due Reminder in Hours *" className={'CMlabelDiv'} />
                                    <TextInput
                                        fullWidth

                                        id="outlined-basic"
                                        type={"number"}
                                        variant="outlined"
                                        sx={{ marginBottom: "20px", height: '20px' }}
                                        value={postDueReminder}
                                        onChange={(e: any) => {
                                            const value = e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 5)
                                            setPostDueReminder(value.replace(/\D/g, ""));
                                        }}

                                        maxLength={5}
                                        placeholder='Enter post due reminder in hours'
                                    />
                                </FormControl>
                            </Grid>

                        </Grid>
                    </FormControl>
                </div>
                <div className='butondrower'>
                    {renderButtons()}
                </div>
            </Box>
        </Container>
    );
}