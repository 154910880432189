/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import { withRouter, Link, NavLink, useHistory } from 'react-router-dom';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Axios from 'axios';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Avatar from '@mui/material/Avatar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { paths, ROUTES_PATH } from '../../constants/paths';
import { BASEURL } from '../../constants/apiConstants';
import BellIcon from '../../../assets/images/BellIcon.svg';
import ProfileIcon from '../../../assets/images/ProfileIcon.svg';
// import LoginLogo from '../../../assets/ACGLoginLogo.svg';
import LoginLogo from '../../../assets/ACG-logo.png';
import './index.scss';
import { useDispatch } from 'react-redux';
import { removeLocalStorage } from '../../../utilities/storageUtility';
import { executeACGAction, reset, updateScreenIdentifiers } from '../../store/slice';
import Switch from '../Switch';
import useGetState from '../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS } from '../../constants/apiConstants';
import RightDrawer from '../../../app/atoms/tempDrawer/indexAlt';
import UpdatePassword from '../../features/Login/Templates/UpdatePassword';
import UpdateQuestion from '../../features/Login/Templates/UpdateQuestion';
import SnackbarAlert from '../Snackbar';
import Label from '../Label/index';
import BroadCastIcon from '../../../assets/images/BroadCastIcon.svg';
import CurrentBroadcastCard from '../CurrentBroadCastCard';
import Badge from '@mui/material/Badge';
import RuleEngineService from '../../features/ConditionMonitoring/ruleEngineServices';
import NotificationDetails from '../../features/Notifications';
import { trackErrorsInNewRelic } from '../../utils/newRelicFuncations';

const drawerWidth = 240;

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`
    }
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    })
}));
const settings = [ 'Change Password', 'Logout'];

function MiniDrawer(props: any) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [notifiyOpen, setNotifyOpen] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [anchorCrrBcst, setAnchorCrrBcst] = useState<null | HTMLElement>(null);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const [currentBroadcast, setCurrentBroadcast] = useState<any>([]);
    const [notificationCount, setNotificationCount] = useState<number>(0);
    // { BroadcastMessage: 'Heelo', DateTimeRange: 'time' } bradcast obj

    const history = useHistory();
    const state = useGetState();
    const [updateProfile, setUpdateProfile] = useState(false);
    const [updatePassword, setUpdatePassword] = useState(false);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [routes, setRoutes] = useState([]);
    const userName = state?.[STORE_KEYS.USER_DTL];

    const [alertType, setAlertType] = useState('');
    const [alertDetails, setAlertDetails] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useDispatch();
    const getFormat = (__data: any) => {
        const result: any = [];
        const keyArr: any = new Set<string>(__data?.map((item: any) => item?.Module));
        const key: any = [...keyArr];
        key.forEach((k: any) => {
            const obj: any = {};
            const arr: any = [];
            __data.forEach((item: any) => {
                if (item.Module == k) {
                    arr.push(item);
                }
            });
            obj['module'] = k;
            obj['value'] = arr;
            result.push(obj);
        });
        return result;
    };

    const getCurrentBroadcast = () => {
        const payload = {
            payload: {
                urlPath: ACTION_CODES.GET_CRR_BRDCAST,
                requestType: 'GET'
            },
            uniqueScreenIdentifier: { isCrrBroadcast: true },
            storeKey: STORE_KEYS.CURRENT_BROADCAST
        };
        dispatch(executeACGAction(payload));
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.CURRENT_BROADCAST]?.body?.data) {
            setCurrentBroadcast(state?.[STORE_KEYS.CURRENT_BROADCAST]?.body?.data);
        }
    }, [state?.[STORE_KEYS.CURRENT_BROADCAST]?.body?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ROUTES_PRIVILEGES]) {
            const __routes = getFormat(state?.[STORE_KEYS.ROUTES_PRIVILEGES]);
            console.log("🚀 ~ useEffect ~ __routes:", __routes)
            setRoutes(__routes);
        }
    }, [state?.[STORE_KEYS.ROUTES_PRIVILEGES]]);

    const handleLogout = () => {
        const token = state?.[STORE_KEYS.USER_DTL].token;
        const email = state?.[STORE_KEYS.USER_DTL].EmailId;
        const payload = { token: token, EmailId: email }
        Axios({
            url: BASEURL + '/logout',
            method: 'post',
            responseType: 'blob',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    window.newrelic.addPageAction('LoggedOutUser', {
                        LoggedIn: email, // Ensure you have the current user ID
                        logoutTime: new Date().toISOString(),
                      });
                    removeLocalStorage('token');
                    dispatch(reset());
                    handleCloseUserMenu();
                    history.push(paths.LOGIN.path[0]);
                }
            })
            .catch((error) => {
                trackErrorsInNewRelic({error})
                console.log(error);
            });

    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleOpenNotificationMenu = (event: React.MouseEvent<HTMLElement>) => {
        // !notifiyOpen ? setNotifyOpen(event.currentTarget) : handleCloseNotificationMenu();
        history.push('/notifications')
    }

    const handleCloseNotificationMenu = () => {
        setNotifyOpen(null);
    }

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        !anchorElUser ? setAnchorElUser(event.currentTarget) : handleCloseUserMenu();
        dispatch(
            updateScreenIdentifiers({
                storeKey: STORE_KEYS.GLOBAL_DRAWER,
                uniqueScreenIdentifier: {
                    body: true
                }
            })
        );
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
        dispatch(
            updateScreenIdentifiers({
                storeKey: STORE_KEYS.GLOBAL_DRAWER,
                uniqueScreenIdentifier: {
                    body: false
                }
            })
        );
    };

    const handleOpenCrrBcst = (event: React.MouseEvent<HTMLElement>) => {
        //  getCurrentBroadcast();
        !anchorElUser ? setAnchorCrrBcst(event.currentTarget) : handleCloseUserMenu();
    };

    const handleCloseCrrBcst = () => {
        setAnchorCrrBcst(null);
    };

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    const handleOpen = async (values: any) => {
        const type = await values.RuleType
        setAlertType(type);

        const token = state?.[STORE_KEYS.USER_DTL].token;
        const body = { ruleKey: values.CondAlertKey, type: values.RuleType, token: token }
        await RuleEngineService.getRuleEngineNotificationDetails(body)
            .then(async (response: any) => {
                const result = await response?.data.message[0];
                console.log(result[0])
                setAlertDetails(result[0]);

            })
            .catch((err) => {
                console.log(err);
            });
        setOpenModal(true);
    }

    const handleClose = () => {
        setAlertType('');
        setOpenModal(false);
    }

    useEffect(() => {
        if (state?.[STORE_KEYS.UNACKNOWLEDGED_NOTIFICATION_COUNT]?.body?.data) {
            setNotificationCount(state?.[STORE_KEYS.UNACKNOWLEDGED_NOTIFICATION_COUNT]?.body?.data?.[0]?.NotificationCount);
        }
    }, [state?.[STORE_KEYS.UNACKNOWLEDGED_NOTIFICATION_COUNT]?.body?.data]);

    return (
        <div className="Navigation">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            {location.hash === `#${paths.LOGIN.path[0]}` || location.hash === `#${paths.FORGOT_PWD.path}` ? null : (
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <AppBar position="fixed" open={open} className={open ? 'appbar openedAppbar' : 'appbar'}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    marginRight: 5,
                                    ...(open && { display: 'none' })
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" noWrap component="div">
                                <Link to={state?.[STORE_KEYS.DEFAULT_PAGE]}>
                                    {' '}
                                    <img src={LoginLogo} alt="logo" style={{ width: '100px' }} />
                                </Link>
                            </Typography>
                            <Box sx={{ flexGrow: 1 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                    {currentBroadcast?.length > 0 ? (
                                        <Badge color="secondary" variant="dot" className="badge-custom-css">

                                            <IconButton
                                                onClick={async (e: any) => {
                                                    handleOpenCrrBcst(e);
                                                    await getCurrentBroadcast();
                                                    // handleOpenCrrBcst(e);
                                                }}
                                                size="large"
                                                aria-label="show 17 new notifications"
                                                color="inherit"
                                            >
                                                <Tooltip title={"Broadcast"}><img src={BroadCastIcon} alt="notify" /></Tooltip>
                                            </IconButton>
                                        </Badge>
                                    ) : (
                                        <IconButton
                                            onClick={async (e: any) => {
                                                await getCurrentBroadcast();
                                                handleOpenCrrBcst(e);
                                            }}
                                            size="large"
                                            aria-label="show 17 new notifications"
                                            color="inherit"
                                        >
                                            <Tooltip title={"Broadcast"}><img src={BroadCastIcon} alt="notify" /></Tooltip>
                                        </IconButton>
                                    )}

                                    <IconButton style={{ position: "relative" }} size="large" aria-label="show 17 new notifications" color="inherit" onClick={handleOpenNotificationMenu}>
                                        <Tooltip style={{ marginTop: 10 }} title={"Notifications"}>
                                            <Badge badgeContent={props.alertsData.length} color="primary">
                                                <img src={BellIcon} alt="bell icon" width={25} height={25} />
                                                <div className='notificationCount'>
                                                {notificationCount > 99 ? '99+' : notificationCount}
                                                </div>
                                            </Badge>
                                        </Tooltip>
                                    </IconButton>

                                    {/* <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={notifiyOpen}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                        open={Boolean(notifiyOpen)}
                                        onClose={handleCloseNotificationMenu}
                                        className="profileDropDown"
                                    >
                                        {props.alertsData.map((setting: any) => {
                                            return (
                                                <MenuItem
                                                    key={setting}
                                                    onClick={() => handleOpen(setting)} sx={{ color: 'text.secondary' }}
                                                    className="actionmenu"
                                                >
                                                    <ListItem
                                                        style={{ padding: '5px', paddingLeft: '0px', flexDirection: 'column', alignItems: "baseline" }}

                                                    >
                                                        {setting?.NotifMessage?.split(".").map((item: any) => {

                                                            return (
                                                                <>
                                                                    <Typography>{item}</Typography>
                                                                </>
                                                            )
                                                        })}
                                                    </ListItem>

                                                </MenuItem>
                                            )
                                        })}
                                    </Menu> */}
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={notifiyOpen}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                        open={Boolean(notifiyOpen)}
                                        onClose={handleCloseNotificationMenu}
                                        className="profileDropDown notifybox"
                                    >
                                    </Menu>
                                    <IconButton onClick={handleOpenUserMenu}>
                                        <Avatar
                                            alt="Remy Sharp"
                                            src={ProfileIcon}
                                            style={{ width: '35px', height: '35px' }}
                                        />
                                        <Typography variant="caption" className="profileMenuDiv">
                                            {userName?.UserName}
                                            {anchorElUser ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </Typography>
                                    </IconButton>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                        className="profileDropDown"
                                    >
                                        {settings.map((setting: any) => (
                                            <MenuItem
                                                key={setting}
                                                // onClick={handleCloseUserMenu}
                                                sx={{ color: 'text.secondary', padding: 0 }}
                                                className="actionmenu"
                                            >
                                                {setting === 'Notifications' || setting === 'Coach Marks' ? (
                                                    <Typography textAlign="center" style={{padding: "6px 16px 6px 16px",  width: "100%"}}>
                                                        {setting} <Switch />
                                                    </Typography>
                                                ) : setting === 'Logout' ? (
                                                    <Typography textAlign="center" style={{padding: "6px 16px 6px 16px", width: "100%"}} onClick={handleLogout}>
                                                        <a>{setting}</a>
                                                    </Typography>
                                                ) : setting === 'Security Question' ? (
                                                    <Typography textAlign="center" style={{padding: "6px 16px 6px 16px",  width: "100%"}} onClick={() => setUpdateProfile(true)}>
                                                        <a>{setting}</a>
                                                    </Typography>
                                                ) : setting === 'Change Password' ? (
                                                    <Typography textAlign="center" style={{padding: "6px 16px 6px 16px",  width: "100%"}} onClick={() => setUpdatePassword(true)}>
                                                        <a>{setting}</a>
                                                    </Typography>
                                                ) : (
                                                    <Typography
                                                        textAlign="center"
                                                        onClick={handleCloseUserMenu}
                                                        style={{ padding: '5px', paddingLeft: '0px',  width: "100%" }}
                                                    >
                                                        {setting}
                                                    </Typography>
                                                )}
                                                {/* {setting === 'Logout' ? (
                                                    <Typography textAlign="center">
                                                        <a onClick={handleLogout}>{setting}</a>
                                                    </Typography>
                                                ) : (
                                                    <Typography textAlign="center">{setting}</Typography>
                                                )} */}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                    {/* <RightDrawer
                                        handlerOpen={() => { }}
                                        handlerClose={() => handleClose()}
                                        status={openModal}
                                        drawerHeader={'Notification Details'}
                                        alertType={"Custom Alerts"}
                                        children={
                                            <NotificationDetails />
                                        }
                                    /> */}
                                    <RightDrawer
                                        handlerOpen={() => { }}
                                        handlerClose={() => setUpdateProfile(false)}
                                        status={updateProfile}
                                        drawerHeader={'Update Security Question'}
                                        children={
                                            <UpdateQuestion
                                                drawerClose={() => setUpdateProfile(false)}
                                                setSnackbarOpts={setSnackbarOptions}
                                                handleLogout={handleLogout}
                                                removeLocalStorage={removeLocalStorage}
                                            />
                                        }
                                    />
                                    <RightDrawer
                                        handlerOpen={() => { }}
                                        handlerClose={() => setUpdatePassword(false)}
                                        status={updatePassword}
                                        drawerHeader={'Update Password'}
                                        children={
                                            <UpdatePassword
                                                drawerClose={() => setUpdatePassword(false)}
                                                setSnackbarOpts={setSnackbarOptions}
                                                handleLogout={handleLogout}
                                            />
                                        }
                                    />
                                </Box>
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        open={open}
                        className={open ? 'openableDrawer opened' : 'openableDrawer'}
                    >
                        <DrawerHeader>
                            <IconButton onClick={handleDrawerClose}>
                                {open ? <ChevronLeftIcon sx={{ color: 'white' }} /> : null}
                            </IconButton>
                        </DrawerHeader>
                        <Divider />

                        <List onClick={handleDrawerClose}>
                            {routes?.map((__route: any, idx: number) => {
                                return (
                                    <>
                                        {open && (
                                            <Label
                                                className="navLabel"
                                                key={__route?.module}
                                                label={__route?.module?.toUpperCase()}
                                            />
                                        )}
                                        {__route?.value?.map(
                                            (item: any) =>
                                                item.isVisible && (
                                                    <NavLink
                                                        to={item?.path}
                                                        activeClassName="activeLink"
                                                        className="navigationlink"
                                                        key={item?.FeatureCode}
                                                    >
                                                        <Tooltip followCursor title={item?.title}>
                                                            <ListItem disablePadding sx={{ display: 'block' }}>
                                                                <ListItemButton
                                                                    sx={{
                                                                        minHeight: 48,
                                                                        justifyContent: open ? 'initial' : 'center'
                                                                        // px: 2.5,
                                                                    }}
                                                                >
                                                                    <ListItemIcon
                                                                        sx={{
                                                                            minWidth: 0,
                                                                            mr: open ? 3 : 'auto',
                                                                            justifyContent: 'center'
                                                                        }}
                                                                    >
                                                                        <img src={item?.imgSrc} alt="FMicon" />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        // primary={item?.title?.split(' ').join('-')}
                                                                        primary={item?.title}
                                                                        sx={{ opacity: open ? 1 : 0 }}
                                                                    />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        </Tooltip>
                                                    </NavLink>
                                                )
                                        )}
                                    </>
                                );
                            })}
                        </List>

                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorCrrBcst}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            open={Boolean(anchorCrrBcst)}
                            onClose={handleCloseCrrBcst}
                            className="profileDropDown"
                        >
                            <MenuItem>
                                <div style={{ flex: '70%' }}>
                                    <h5
                                        style={{
                                            fontSize: '13px',
                                            color: '#DADCEE',
                                            marginBottom: '10px',
                                            fontFamily: 'Montserrat'
                                        }}
                                    >
                                        Broadcast
                                    </h5>
                                </div>
                            </MenuItem>
                            {currentBroadcast.map((setting: any) => (
                                <CurrentBroadcastCard data={setting} />
                            ))}
                        </Menu>
                    </Drawer>
                </Box>
            )}
        </div>
    );
}

export default withRouter(MiniDrawer);
