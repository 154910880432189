import LoginLogo from '../../../assets/loginBg.svg';
//import Logo from '../../../assets/ACGLoginLogo.svg';
import Logo from '../../../assets/ACG-logo.png';
import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import LoginModuleTemplates from '../Login/Templates/index';
import '../Login/index.scss';
import UseWindowResize from '../../utils/UseWindowResize';
import { Typography } from '@material-ui/core';

const ACGLogin = () => {
    const [templateType, setTemplateType] = useState('forgotPassword');
    const [values, setValues] = useState({});
    const { height } = UseWindowResize();
    const [shortDevice, setShortDevice] = useState(false);

    useEffect(() => {
        setShortDevice(false);
        if (height >= 600 && height <= 750 && templateType === 'forgotPwdDtl') {
            setShortDevice(true);
        }
    }, [height || templateType]);

    return (
        <div
            className={` acgLoginPage ${shortDevice ? ' forgotPwdShortDevice' : ''}`}
            style={{
                backgroundImage: `linear-gradient( rgba(28, 31, 47,0.6), rgba(28, 31, 47,0.7)), url(${LoginLogo})`
            }}
        >
            <div className={` loginForm ${shortDevice ? ' loginFormShortDev' : ''}`}>
                <Box className="loginLogo">
                    <img src={Logo} alt="logo" />
                </Box>

                <LoginModuleTemplates
                    data={{
                        templateType,
                        setTemplateType,
                        isForgotPwdPage: true,
                        values,
                        setValues
                    }}
                />
            </div>
            <Typography className="copyrightLabel">ACG © 2023 All rights reserved.</Typography>
        </div>
    );
};

export default ACGLogin;
