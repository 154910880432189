import React, { useEffect, useRef } from 'react';
import { Chart, LinearScale, CategoryScale, Title, Tooltip, Legend } from 'chart.js';
import { BoxPlotController, BoxAndWiskers } from '@sgratzl/chartjs-chart-boxplot';

// Register necessary ChartJS components
Chart.register(
  BoxPlotController,
  BoxAndWiskers,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend
);

const BoxPlotGraph = ({ data }) => {
  const chartRef = useRef(null); // Ref for the chart's canvas
  const chartInstance = useRef(null); // Ref for the chart instance

  useEffect(() => {
    // Cleanup the previous chart before creating a new one
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    if (chartRef.current && data?.length > 0) {
      const ctx = chartRef.current.getContext('2d'); // Get canvas context

      // Prepare the chart data
          const chartData = {
            labels: data.map((item) => item.LotNos), // Using `LotNos` as the x-axis labels
            datasets: [
              {
                label: 'Metrics', // One dataset for all LotNos
                data: data.map((item) => ({
                  min: item.Metrics.Min,
                  q1: item.Metrics.Min + (item.Metrics.Avg - item.Metrics.Min) / 2, // Estimating Q1
                  median: item.Metrics.Avg, // Using Avg as the median
                  q3: item.Metrics.Avg + (item.Metrics.Max - item.Metrics.Avg) / 2, // Estimating Q3
                  max: item.Metrics.Max,
                  upperError: item.Metrics.Avg + item.Metrics.StdDev, // Upper error bar
                  lowerError: item.Metrics.Avg - item.Metrics.StdDev, // Lower error bar
                })),
                backgroundColor: 'rgba(255, 99, 132, 0.3)', // Box fill color
                borderColor: '#fff', // Box border color
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255, 99, 132, 0.5)', // Hover color
                hoverBorderColor: 'rgba(54, 162, 235, 1)',
              },
            ],
    };

      // Chart options with tooltip customizations
      const options = {
        responsive: true,
        scales: {
          x: {
            ticks: {
              color: '#e0e0e0', // Red color for x-axis labels
            },
            grid: {
              color: '#000', // Light gray color for x-axis gridlines
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              color: '#e0e0e0', // Green color for y-axis labels
            },
            grid: {
              color: '#000', // Light gray color for y-axis gridlines
            },
          },
        },
        plugins: {
          title: {
            display: true,
            // text: 'Box Plot with Std.Dev Error Bars',
          },
          legend: {
            display: false,
            position: 'top',
            labels: {
              boxHeight: 10,
              boxWidth: 20, // Smaller width for circular symbols
              usePointStyle: false, // Use circular symbols instead of square boxes
              color: '#e0e0e0', // Font color for legend labels
              font: {
                size: 10, // Adjust font size
                //family: 'Arial', // Adjust font family
              },
              borderWidth: 0, // Set the border width to 0 to remove the border
              //pointStyle: 'circle', // Ensures the symbols are circular
            },
          },
          // legend: {
          //   position: 'top',
          // },
          tooltip: {
            enabled: true,
            mode: 'index',
            intersect: false,
            backgroundColor: 'rgba(0,0,0,0.7)', // Dark background for the tooltip
            titleFont: {
              size: 10,
              weight: 'normal',
              family: 'Arial',
            },
            bodyFont: {
              size: 10,
              family: 'Arial',
            },
            padding: 10,
            cornerRadius: 5,
            bodySpacing: 5,
            callbacks: {
              // Custom tooltip content
              label: (context) => {
                const datasetIndex = context.datasetIndex;
                const label = context.dataset.label || '';
                const dataPoint = context.raw;
                return [
                  `${label}:- `,
                  `Min: ${dataPoint.min?.toFixed(2)}`,
                  `Q1: ${dataPoint.q1?.toFixed(2)}`,
                  `Median: ${dataPoint.median?.toFixed(2)}`,
                  `Q3: ${dataPoint.q3?.toFixed(2)}`,
                  `Max: ${dataPoint.max?.toFixed(2)}`
                ];
               // return `${label} | Min: ${dataPoint.min}\nQ1: ${dataPoint.q1}\nMedian: ${dataPoint.median}\nQ3: ${dataPoint.q3}\nMax: ${dataPoint.max}`;
                // return `${label} | Min: ${dataPoint.min}, <br /> Q1: ${dataPoint.q1}, <br />Median: ${dataPoint.median}, <br />Q3: ${dataPoint.q3}, Max: ${dataPoint.max}`;
              },
            },
          },
        },
        layout: {
          padding: {
            bottom: 10, // Adjust bottom padding to help with the bottom label alignment
          },
        },
        elements: {
          line: {
            borderColor: '#fff', // Black color for T-lines (whiskers)
            borderWidth: 1, // T-line thickness
          },
        },
      };      

      // Create the chart with the prepared chartData
      chartInstance.current = new Chart(ctx, {
        type: 'boxplot', // Using the 'boxplot' type
        data: chartData,
        options: options,
      });
    }

    // Cleanup function when component is unmounted or data changes
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]); // Re-run the effect when 'data' changes

  return (

      <canvas ref={chartRef}/>
    
  );
};

export default BoxPlotGraph;