import { useState } from 'react';
import { Box } from '@mui/material';
// import Logo from '../../../assets/ACGLoginLogo.svg';
import Logo from '../../../assets/ACG-logo.png';
import LoginLogo from '../../../assets/loginBg.svg';
import LoginModuleTemplates from './Templates/index';
import './index.scss';
import { Typography } from '@material-ui/core';

const ACGLogin = () => {
    const [templateType, setTemplateType] = useState('simpleLogin');
    const [values, setValues] = useState({});
    return (
        <div
            className="acgLoginPage"
            style={{
                backgroundImage: `linear-gradient( rgba(28, 31, 47,0.6), rgba(28, 31, 47,0.7)), url(${LoginLogo})`
            }}
        >
            <div className="loginForm">
                <Box className="loginLogo">
                    <img src={Logo} alt="logo" />
                </Box>
                <LoginModuleTemplates
                    data={{
                        templateType,
                        setTemplateType,
                        setValues,
                        values
                    }}
                />
            </div>
            <Typography className='copyrightLabel' >ACG © 2023 All rights reserved.</Typography>
        </div>
    );
};

export default ACGLogin;
