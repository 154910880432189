import { useState, useEffect , useMemo} from 'react';
import Axios from 'axios';
import { Line } from 'react-chartjs-2';
import useGetState from '../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../constants/apiConstants';
import Button from '@mui/material/Button';
import DropDown from '../../../atoms/Dropdown';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './BoxPlotChart';
import './Index.scss';
// import BoxPlotChart from './BoxPlotChart';
import { trackErrorsInNewRelic } from '../../../utils/newRelicFuncations';
import BoxPlotGraph from './BoxPlotChart';
import MultipleSelectCheckmarks from '../../../atoms/MultiselectDropDown';
import { Tooltip } from '@mui/material';
import { color } from 'html2canvas/dist/types/css/types/color';
type ComponentProps = {
    allFilters: any;
    temperatureFilters: any;
    bKey: string | number;
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#2e304a',
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const CCPReport = (props: ComponentProps) => {
    const { temperatureFilters, allFilters, bKey } = props;
    const [tempParam1, setTempParam1] = useState('');
    const [tempParam2, setTempParam2] = useState('');
    const [allParam1, setAllParam1] = useState('');
    const [allParam2, setAllParam2] = useState('');
    const [temparatureChartData, setTemperatureChartData] = useState<any[] | undefined | any>([]);
    const [temp1Value, setTemp1Value] = useState([]);
    const [temp2Value, setTemp2Value] = useState([]);
    const [tempDiffData, settempDiffData] = useState([]);
    const [allChartData, setAllChartData] = useState<any[] | undefined | any>([]);
    const [all1Value, setAll1Value] = useState([]);
    const [all2Value, setAll2Value] = useState([]);
    const [chart1Page, setChart1Page] = useState<number>(1);
    const [chart2Page, setChart2Page] = useState<number>(1);
    const [timestamparr, setTimestamparr] = useState([]);
    const [timestamparr2, setTimestamparr2] = useState([]);
    const [graphdataarr, setGraphdataarr] = useState([]);
    const [batchListChart2, setBatchListChart2] = useState([]);
    const [batchList, setBatchList] = useState([]);
    const [phaseList, setPhaseList] = useState([]);
    const [parameterList, setParameterList] = useState([]);

    const [selectedBatch, setSelectedBatch] = useState<any>([]);
    const [selectedPhase, setSelectedPhase] = useState<any>();
    const [selectedParameter, setSelectedParameter] = useState<any>();
    const [labels, setLabels] = useState<number[]>([]);
    const [parameterBatchData, setParameterBatchData] = useState<any>([]);
    const [char1TotalPages, setChart1TotalPages] = useState(0);
    const [chart2TotalPages, setChart2TotalPages] = useState(0);

    const [chart2Data, setChart2Data] = useState<any>([]);
    const [chart2Label, setChart2Label] = useState<[]>([]);

    const [parameterGroupList, setParameterGroupList] = useState([]);
    const [phaseListForChart2, setPhaseListForChart2] = useState([]);
    const [parameterListForChart2, setParameterListForChart2] = useState([]);

    const [selectedBatchForChart2, setSelectedBatchForChart2] = useState<any>([]);
    const [selectedParamterGroupForChart2, setSelectedParameterGroupForChart2] = useState<any>([]);
    const [selectedParamterForChart2, setSelectedParamterForChart2] = useState<any>([]);
    const [selectedPhaseForChart2, setSelectedPhaseForChart2] = useState<any>();
    const moment = require('moment');
    const { plantKey, machineNameKey, tempStartDate, tempEndDate, productKey, recipeKey } = allFilters;

    const graphUnits = '';
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    function createData(name: string, parameter1: number, parameter2: number) {
        return { name, parameter1, parameter2 };
    }

    function Unit() {
        if (graphUnits === undefined || graphUnits === null || graphUnits === '') {
            return '°C';
        } else {
            return graphUnits;
        }
    }

    const UnitTxt = Unit();

    const rows = [createData('Min', 159, 6.0), createData('Max', 237, 9.0), createData('Average', 262, 16.0)];
    const [selectedOption, setSelectedOption] = useState('TrendComparison');

    // Handler to update the selected option when a radio button is clicked
    const handleOptionChange = (event: any) => {
        setSelectedOption(event.target.value);
    };

    const getGraphdata = async () => {
        const payload = {
            PhaseKey: selectedPhase,
            MachineKey: machineNameKey,
            MMParamKey: selectedParameter,
            BatchKeys: selectedBatch?.map((e: any) => e.value).join(','),
            PageNo: chart1Page
        };
        await Axios({
            url: BASEURL + '/cpp/get-cpp-parameter-batch-chart',
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                // console.log(response.data.body);
                setParameterBatchData(response?.data?.body?.data);
                setChart1TotalPages(response?.data?.body?.data?.totalPage);
            })
            .catch((error) => {});
    };

    const getGraphdataForChart2 = async () => {
        const payload = {
            PhaseKey: selectedPhaseForChart2,
            MachineKey: machineNameKey,
            MMParamKey: selectedParamterForChart2?.map((e: any) => e.value).join(','),
            BatchKeys: selectedBatchForChart2,
            PageNo: chart2Page
        };
        await Axios({
            url: BASEURL + '/cpp/get-cpp-parameter-parameter-chart',
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                // console.log(response.data.body);
                setChart2Data(response?.data?.body?.data);
                setChart2TotalPages(response?.data?.body?.data?.totalPage);
            })
            .catch((error) => {});
    };

    const getParameterList = async () => {
        const payload = {
            MachineKey: machineNameKey,
            BatchKeys: selectedBatch?.map((e: any) => e.value).join(','),
            CPPParamGroupKeys: 'All'
        };
        try {
            const { data } = await Axios({
                method: 'post',
                url: `${BASEURL}/cpp/get-parameter-list`,
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            setParameterList(
                data?.body?.data?.map((e: any) => ({ name: e?.ParamName, code: e?.MMParamKey, UOM: e?.UOM }))
            );
        } catch (error) {}
    };

    const getPhaseList = async () => {
        const payload = {
            MachineKey: machineNameKey,
            BatchKeys: selectedBatch?.map((e: any) => e.value).join(',')
        };
        try {
            const { data } = await Axios({
                method: 'post',
                url: `${BASEURL}/cpp/get-phases-list`,
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            setPhaseList(data?.body?.data?.map((e: any) => ({ name: e?.PhaseName, code: e?.PhaseKey })));
        } catch (error) {}
    };

    const getBatcheList = async () => {
        const payload = {
            PlantKey: plantKey,
            MachineKey: machineNameKey,
            FromDate: tempStartDate,
            ToDate: tempEndDate,
            CustProductKey: productKey,
            RecipeKeys: recipeKey
        };
        try {
            const { data } = await Axios({
                method: 'post',
                url: `${BASEURL}/cpp/get-lots-list`,
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });

            setBatchList(data?.body?.data?.map((e: any) => ({ label: e?.BatchNo, value: e?.BatchKey })));
            setBatchListChart2(data?.body?.data?.map((e: any) => ({ name: e?.BatchNo, code: e?.BatchKey })));
        } catch (error) {}
    };

    useEffect(() => {
        setSelectedParameter('');
        setSelectedBatchForChart2([]);
        setSelectedBatch([]);
        setSelectedParamterForChart2([]);
        setSelectedPhaseForChart2('');
        setSelectedPhase('');
        setParameterBatchData([]);
        setChart2Data([]);
        setChart2Label([]);
    }, [recipeKey]);

    useEffect(() => {
        setSelectedParameter('');
        setSelectedPhase('');
        setChart1Page(1);
    }, [selectedBatch]);

    useEffect(() => {
        setSelectedParamterForChart2([]);
        setSelectedPhaseForChart2("");
        setSelectedParameterGroupForChart2([]);
        setChart2Data([]);
        setChart2Label([]);
    }, [selectedBatchForChart2]);

    const getParameterListForChart2 = async () => {
        const payload = {
            MachineKey: machineNameKey,
            BatchKeys: selectedBatchForChart2,
            CPPParamGroupKeys: selectedParamterGroupForChart2?.map((e: any) => e.value).join(',')
        };
        try {
            const { data } = await Axios({
                method: 'post',
                url: `${BASEURL}/cpp/get-parameter-list`,
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            setParameterListForChart2(
                data?.body?.data?.map((e: any) => ({ label: e?.ParamName, value: e?.MMParamKey, UOM: e?.UOM }))
            );
        } catch (error) {}
    };

    const getParameterGroupListForChart2 = async () => {
        const payload = {
            MachineKey: machineNameKey,
            BatchKeys: selectedBatchForChart2 || 'All'
        };
        try {
            const { data } = await Axios({
                method: 'post',
                url: `${BASEURL}/cpp/get-parameter-group-list`,
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            setParameterGroupList(
                data?.body?.data?.map((e: any) => ({ label: e?.GroupName, value: e?.CPPParamGroupKey }))
            );
        } catch (error) {}
    };

    const getPhaseListForChart2 = async () => {
        const payload = {
            MachineKey: machineNameKey,
            BatchKeys: selectedBatchForChart2 || 'All'
        };
        try {
            const { data } = await Axios({
                method: 'post',
                url: `${BASEURL}/cpp/get-phases-list`,
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            setPhaseListForChart2(data?.body?.data?.map((e: any) => ({ name: e?.PhaseName, code: e?.PhaseKey })));
        } catch (error) {}
    };

    useEffect(() => {
        selectedBatchForChart2[0] && getPhaseListForChart2();
        selectedBatchForChart2[0] && getParameterGroupListForChart2();
    }, [selectedBatchForChart2]);

    useEffect(() => {
        selectedParamterGroupForChart2[0] && getParameterListForChart2();
    }, [selectedParamterGroupForChart2]);

    useEffect(() => {
        allFilters?.recipeKey && getBatcheList();
    }, [allFilters?.recipeKey, machineNameKey, tempStartDate, tempEndDate]);

    let chart1NextPageConditionCheck = char1TotalPages <= chart1Page;
    let chart2NextPageConditionCheck = chart2TotalPages <= chart2Page;

    const pagination = async (type: string, count: number) => {
        if (type === 'temp_chart1') {
            setChart1Page(count);
        } else {
            setChart2Page(count);
        }
    };

    const graphColor = {
        actual: '#95C3FE',
        max: '#C26E68',
        min: '#ECA546',
        setValue: '#8BCD9D',
        tooltipBG: '#000fff',
        tooltipText: '#fff'
    };

    useEffect(() => {
        // Check if `parameterBatchData` and `parameterBatchData.graphData` are available
        if (parameterBatchData?.graphData?.length > 0) {
            // Set the state with `RunningDurationInMins` values from the first item
            const firstData = parameterBatchData.graphData[0]?.data || [];
            const runningDurationLabels = firstData.map((e: any) => e?.RunningDurationInMins).filter(Boolean);
            setLabels(runningDurationLabels);
        } else {
            // If no valid data, set an empty array
            setLabels([]);
        }
    }, [parameterBatchData]); // Re-run when `parameterBatchData` changes

    useEffect(() => {
        // Check if `parameterBatchData` and `parameterBatchData.graphData` are available
        if (chart2Data?.graphData?.length > 0) {
            // Set the state with `RunningDurationInMins` values from the first item
            const firstData = chart2Data.graphData[0]?.data || [];
            const runningDurationLabels = firstData.map((e: any) =>
                moment(e?.EventTime).utc().format('DD-MM HH:mm:ss')
            );
            setChart2Label(runningDurationLabels);
        } else {
            // If no valid data, set an empty array
            setChart2Label([]);
        }
    }, [chart2Data]); // Re-run when `chart2Data` changes

    const parameterBatchGraphData =  useMemo(() => {
            if (!parameterBatchData?.graphData?.length) {
                return [];
            }
            return parameterBatchData.graphData.map((e: any, index: number) => {
                return {
                    label: e?.LotNos || 'Unknown Lot', // Default to 'Unknown Lot' if LotNos is missing
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: Object.values(graphColor)[index] || '#000000', // Default to black if graphColor.max is missing
                    borderColor: Object.values(graphColor)[index] || '#000000',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    pointBorderColor: Object.values(graphColor)[index] || '#000000',
                    pointBackgroundColor: Object.values(graphColor)[index] || '#000000',
                    pointBorderWidth: 0,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: Object.values(graphColor)[index] || '#000000',
                    pointHoverBorderColor: Object.values(graphColor)[index] || '#000000',
                    pointHoverBorderWidth: 0,
                    pointRadius: 3,
                    pointHitRadius: 10,
                    data: e?.data?.map((dataPoint: any) => dataPoint?.MetricValue) || [] // Ensure data is never undefined
                };
            });
        }, [parameterBatchData]); // Re-run only if parameterBatchData or graphColor change

    const parameterParameterGraphData = () => {
        if (!chart2Data?.graphData?.length) {
            return [];
        }
        const getYAxisIDs = (inputArray: number[]): string[] => {
            // Create an array of unique values
            const uniqueValues = Array.from(new Set(inputArray));

            // Map unique values to yAxisID based on their index
            return inputArray.map((value) => {
                const index = uniqueValues.indexOf(value); // Find the index of the unique value
                return index + 1 == 1 ? 'y' : `yAxisID${index + 1}`; // Assign yAxisID based on the index (1-based, so we add 1)
            });
        };
        const value = getYAxisIDs(chart2Data.graphData.map((e: any) => e.UOM));
        return chart2Data.graphData.map((e: any, index: number) => {
            return {
                label: e?.ParamName || 'Unknown Lot', // Default to 'Unknown Lot' if LotNos is missing
                fill: false,
                lineTension: 0.1,
                backgroundColor: Object.values(graphColor)[index] || '#000000', // Default to black if graphColor.max is missing
                borderColor: Object.values(graphColor)[index] || '#000000',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                pointBorderColor: Object.values(graphColor)[index] || '#000000',
                pointBackgroundColor: Object.values(graphColor)[index] || '#000000',
                pointBorderWidth: 0,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: Object.values(graphColor)[index] || '#000000',
                pointHoverBorderColor: Object.values(graphColor)[index] || '#000000',
                pointHoverBorderWidth: 0,
                pointRadius: 3,
                pointHitRadius: 10,
                yAxisID: value[index],
                data: e?.data?.map((dataPoint: any) => dataPoint?.ParamValue) || [] // Ensure data is never undefined
            };
        });
    };

    const Option: any = {
        interaction: {
            mode: 'index',
            intersect: false,
            color: '#e0e0e0'
        },
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    boxHeight: 5,
                    boxWidth: 20, // Smaller width for circular symbols
                    usePointStyle: false, // Use circular symbols instead of square boxes
                    color: '#e0e0e0', // Font color for legend labels
                    font: {
                        size: 10 // Adjust font size
                        //family: 'Arial', // Adjust font family
                    },
                    borderWidth: 0 // Set the border width to 0 to remove the border
                    //pointStyle: 'circle', // Ensures the symbols are circular
                }
            },
            tooltip: {
                enabled: true,
                position: 'nearest',
                backgroundColor: 'rgba(0, 0, 0, 0.8)', // Adjusted background color for better visibility
                titleFont: {
                    size: 10, // Reduced font size for the tooltip title
                    weight: 'bold' // Optional: Make the title bold
                },
                bodyFont: {
                    size: 10 // Reduced font size for the tooltip body
                },
                footerFont: {
                    size: 10 // Optional: Font size for the footer if used
                },
                padding: 8, // Adjust padding inside the tooltip
                cornerRadius: 4 // Rounded corners for the tooltip
            }
        },
        scales: {
            y: {
                // This is the default y-axis (yAxisID1)
                position: 'left',
                ticks: {
                    beginAtZero: true,
                    color: '#e0e0e0'
                },
                color: '#e0e0e0'
                // This is the second y-axis (yAxisID2)
            },
            yAxisID2: {
                color: '#e0e0e0',
                position: 'right', // Position on the right side
                ticks: {
                    beginAtZero: true,
                    color: '#e0e0e0'
                },
                grid: {
                    color: '#e0e0e0',
                    drawOnChartArea: false // Optionally hide gridlines for the second y-axis
                }
            },
            x: {
                ticks: {
                    color: '#e0e0e0'
                },
                title: {
                    display: true,
                    text: 'Time ',
                    color: '#e0e0e0'
                }
            }
        },
        tooltips: {
            position: 'nearest',
            backgroundColor: graphColor.tooltipBG,
            titleFontColor: graphColor.tooltipText,
            bodyFontColor: graphColor.tooltipText,
            mode: 'index',
            intersect: false
        }
    };

    const OptionForChart1: any = {
        interaction: {
            mode: 'index',
            intersect: false,
            color: '#e0e0e0'
        },
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    boxHeight: 5,
                    boxWidth: 20, // Smaller width for circular symbols
                    usePointStyle: false, // Use rectangular symbols instead of circles
                    color: '#e0e0e0', // Font color for legend labels
                    font: {
                        size: 9 // Adjust font size
                    },
                    borderWidth: 0 // Set the border width to 0 to remove the border
                }
            },
            tooltip: {
                enabled: true,
                position: 'nearest',
                backgroundColor: 'rgba(0, 0, 0, 0.8)', // Adjusted background color for better visibility
                titleFont: {
                    size: 10, // Reduced font size for the tooltip title
                    weight: 'bold' // Optional: Make the title bold
                },
                bodyFont: {
                    size: 10 // Reduced font size for the tooltip body
                },
                footerFont: {
                    size: 10 // Optional: Font size for the footer if used
                },
                padding: 8, // Adjust padding inside the tooltip
                cornerRadius: 4 // Rounded corners for the tooltip
            }
        },
        scales: {
            y: {
                position: 'left',
                color: '#e0e0e0',
                ticks: {
                    beginAtZero: true,
                    color: '#e0e0e0'
                }
            },
            x: {
                ticks: {
                    color: '#e0e0e0'
                },
                title: {
                    display: true,
                    text: 'Time (Minutes)',
                    color: '#e0e0e0'
                }
            },
           
        }
    };

    useEffect(() => {
        selectedBatch[0] && getParameterList();
    }, [selectedBatch]);

    useEffect(() => {
        selectedBatch[0] && getPhaseList();
    }, [selectedBatch]);

    useEffect(() => {
        selectedPhase && selectedParameter && selectedBatch[0] && getGraphdata();
    }, [selectedPhase, selectedParameter, selectedBatch, chart1Page]);

    useEffect(() => {
        selectedPhaseForChart2 && selectedParamterForChart2 && selectedBatchForChart2 && getGraphdataForChart2();
    }, [selectedPhaseForChart2, selectedParamterForChart2, selectedBatchForChart2, chart2Page]);

    useEffect(() => {
        setParameterGroupList([]);
        setSelectedParameterGroupForChart2([]);
        setChart2Page(1);
    }, [selectedBatchForChart2]);

    useEffect(() => {
        setChart2Page(1);
        setSelectedParamterForChart2([]);
    }, [parameterListForChart2]);

    useEffect(() => {
        setChart2Page(1);
        setSelectedParameterGroupForChart2([]);
    }, [parameterGroupList]);

    useEffect(()=>{
    if (!phaseList || !parameterBatchData ) {
        chart1NextPageConditionCheck = false
    }
   
    },[phaseList,parameterGroupList])

    return (
        <div className={`mainarea parent-div ${selectedOption}`}>
            <div className="leftcontent">
                <div className="wrapdivs firstgraphWrap">
                    <div className="graph_div firstgraph">
                        <div className="selectparm selectt">
                            <label>Parameter-Batch Variation</label>
                            <div className="cppFilter">
                                <div className="filterDropdownForCpp">
                                    <MultipleSelectCheckmarks
                                        className="inputDiv dropdownbx"
                                        options={batchList}
                                        removeShowAll
                                        maxSelect={5}
                                        placeHolder="Select Lot(s)"
                                        value={selectedBatch}
                                        setValue={setSelectedBatch}
                                    />
                                </div>
                                <div className="filterDropdownForCpp">
                                    <DropDown
                                        onChange={(e) => {
                                            setSelectedParameter(e.target.value);
                                            setChart1Page(1);
                                        }}
                                        className="inputDiv dropdownbx"
                                        name="dropdownModell"
                                        items={parameterList}
                                        placeHolder="Select Parameter"
                                        value={selectedParameter}
                                    />
                                </div>{' '}
                                <div className="filterDropdownForCpp">
                                    <DropDown
                                        onChange={(e) => {
                                            setChart1Page(1);
                                            setSelectedPhase(e.target.value);
                                        }}
                                        className="inputDiv dropdownbx"
                                        name="dropdownModel"
                                        items={phaseList}
                                        placeHolder="Select Phase"
                                        value={selectedPhase}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="graphcontent">
                            <div style={{ marginTop: '0px' }}>
                                {selectedOption === 'TrendComparison' && selectedParameter && selectedPhase && (
                                    <Line
                                        data={{
                                            labels: labels,
                                            datasets: parameterBatchGraphData,
                                        }}
                                        height={150}
                                        options={OptionForChart1}
                                    />
                                )} 
                                <div style={{ background: 'transparent' }}>
                                    {selectedOption === 'BoxPlot' && selectedParameter && selectedPhase &&(
                                        <BoxPlotGraph data={parameterBatchData?.tableData ?? []} />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="radiocpp">
                            <label>
                                <input
                                    type="radio"
                                    value="TrendComparison"
                                    checked={selectedOption === 'TrendComparison'}
                                    onChange={handleOptionChange}
                                />
                                <span>Trend Comparison</span>
                            </label>

                            <label style={{ marginLeft: '10px' }}>
                                <input
                                    type="radio"
                                    value="BoxPlot"
                                    checked={selectedOption === 'BoxPlot'}
                                    onChange={handleOptionChange}
                                />
                                <span> Box Plot</span>
                            </label>
                        </div>
                      {selectedOption !== 'BoxPlot' &&  <div className="buttonwrap fontHeightWidth cppbutons">
                            <Button
                                variant="contained"
                                size="small"
                                style={
                                    chart1Page === 1
                                        ? { padding: '10px' }
                                        : {
                                              color: '#e0e0e0',
                                              textTransform: 'capitalize',
                                              lineHeight: '1',
                                              padding: '5px 10px'
                                          }
                                }
                                disabled={chart1Page <= 1 ? true : false}
                                onClick={() => pagination('temp_chart1', chart1Page - 1)}
                            >
                                Previous
                            </Button>
                            <Button
                                variant="contained"
                                size="small"
                                style={
                                    chart1NextPageConditionCheck
                                        ? { padding: '10px' }
                                        : {
                                              color: '#e0e0e0',
                                              textTransform: 'capitalize',
                                              lineHeight: '1',
                                              padding: '5px 10px'
                                          }
                                }
                                disabled={chart1NextPageConditionCheck ? true : false}
                                onClick={() => pagination('temp_chart1', chart1Page + 1)}
                            >
                                Next
                            </Button>
                        </div>}
                    </div>
                    <div className="table_div">
                        <div className="scrollDiv" style={{height:"100%"}}>
                            <TableContainer component={Paper} style={{height:"100%" }}>
                                <Table sx={{ minWidth: 100 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">&nbsp;</StyledTableCell>
                                            {parameterBatchData?.tableData?.map((item: any) => (
                                                <StyledTableCell key={item?.LotNos} align="center">
                                                    {item?.LotNos}
                                                </StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key="Min">
                                            <TableCell align="left">Min</TableCell>
                                            {parameterBatchData?.tableData?.map((item: any) => (
                                                <TableCell key={item?.LotNos} align="center">
                                                    {item?.Metrics.Min}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow key="Max">
                                            <TableCell align="left">Max</TableCell>
                                            {parameterBatchData?.tableData?.map((item: any) => (
                                                <TableCell key={item?.LotNos} align="center">
                                                    {item?.Metrics.Max}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow key="Avg">
                                            <TableCell align="left">Avg</TableCell>
                                            {parameterBatchData?.tableData?.map((item: any) => (
                                                <TableCell key={item?.LotNos} align="center">
                                                    {item?.Metrics.Avg}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow key="Std.Dev">
                                            <TableCell align="left">Std. Dev.</TableCell>
                                            {parameterBatchData?.tableData?.map((item: any) => (
                                                <TableCell key={item?.LotNos} align="center">
                                                    {item?.Metrics['Std.Dev']}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
                <div className="wrapdivs secondgraphWrap">
                    <div className="graph_div secondgraph" style={{ borderTop: '10px solid #24263a' }}>
                        <label className="label_style">Parameter-Parameter Variation</label>
                        <div className="selectparm selectt">
                            <div className="cppFilterSecChart">
                                <div className="filterDropdownForCpp">
                                    <DropDown
                                        onChange={(e) => setSelectedBatchForChart2(e.target.value)}
                                        className="inputDiv dropdownbx"
                                        name="dropdownModel"
                                        items={batchListChart2}
                                        placeHolder="Select Lot "
                                        value={selectedBatchForChart2}
                                    />
                                </div>{' '}
                                <div className="filterDropdownForCpp">
                                    <MultipleSelectCheckmarks
                                        className="inputDiv dropdownbx"
                                        options={parameterGroupList}
                                        removeShowAll
                                        maxSelect={2}
                                        placeHolder="Select Parameter Group "
                                        value={selectedParamterGroupForChart2}
                                        setValue={setSelectedParameterGroupForChart2}
                                    />
                                </div>
                                <div className="filterDropdownForCpp">
                                    <MultipleSelectCheckmarks
                                        className="inputDiv dropdownbx"
                                        options={parameterListForChart2}
                                        removeShowAll
                                        maxSelect={5}
                                        placeHolder="Select Parameter "
                                        value={selectedParamterForChart2}
                                        setValue={setSelectedParamterForChart2}
                                    />
                                </div>
                                <div className="filterDropdownForCpp">
                                    <DropDown
                                        onChange={(e) => {
                                            setChart2Page(1);
                                            setSelectedPhaseForChart2(e.target.value);
                                        }}
                                        className="inputDiv dropdownbx"
                                        name="dropdownModel"
                                        items={phaseListForChart2}
                                        placeHolder="Select Phases "
                                        value={selectedPhaseForChart2}
                                    />
                                </div>
                            </div>
                        </div>
                     
                        <div className="graphcontent" style={{ marginBottom: '25px' }}>
                            {selectedPhaseForChart2 && selectedParamterForChart2.length > 0 && (
                                <Line data={{
                                    labels: chart2Label,
                                    datasets: parameterParameterGraphData(),
                                   
                                }} height={150}  options={Option} />
                            )}
                        </div>
                        <div className="buttonwrap fontHeightWidth cppbutons">
                            <Button
                                variant="contained"
                                size="small"
                                style={
                                    chart2NextPageConditionCheck
                                   
                                        ? { padding: '10px' }
                                        : {
                                              background: '#5d97f6',
                                              color: '#e0e0e0',
                                              textTransform: 'capitalize',
                                              lineHeight: '1',
                                              padding: '5px 10px'
                                          }
                                }
                                disabled={chart2NextPageConditionCheck ? true : false}
                                onClick={() => pagination('all_chart2', chart2Page + 1)}
                               
                            >
                                Previous
                            </Button>
                            <Button
                                variant="contained"
                                size="small"
                                style={
                                    chart2Page === 1
                                        ? { padding: '10px' }
                                        : {
                                              background: '#5d97f6',
                                              color: '#e0e0e0',
                                              textTransform: 'capitalize',
                                              lineHeight: '1',
                                              padding: '5px 10px'
                                          }
                                }
                                disabled={chart2Page === 1 ? true : false}
                                onClick={() => pagination('all_chart2', chart2Page - 1)}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                    <div className="table_div" style={{ borderTop: '10px solid #24263a', height: "625px" }}>
                        <div className="scrollDiv" style={{height:"100%"}}>
                            <TableContainer component={Paper} style={{height:"100%" , paddingBottom: "25px"}}>
                                <Table sx={{ minWidth: 100 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">&nbsp;</StyledTableCell>
                                            {chart2Data?.tableData?.map((item: any) => (
                                                <StyledTableCell key={item?.ParamName} align="center">
                                                    {item?.ParamName}
                                                </StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key="Min">
                                            <TableCell align="left">Min</TableCell>
                                            {chart2Data?.tableData?.map((item: any) => (
                                                <TableCell key={item?.ParamName} align="center">
                                                    {item?.Metrics.Min}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow key="Max">
                                            <TableCell align="left">Max</TableCell>
                                            {chart2Data?.tableData?.map((item: any) => (
                                                <TableCell key={item?.ParamName} align="center">
                                                    {item?.Metrics.Max}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow key="Avg">
                                            <TableCell align="left">Avg</TableCell>
                                            {chart2Data?.tableData?.map((item: any) => (
                                                <TableCell key={item?.ParamName} align="center">
                                                    {item?.Metrics.Avg}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow key="Std.Dev">
                                            <TableCell align="left">Std. Dev.</TableCell>
                                            {chart2Data?.tableData?.map((item: any) => (
                                                <TableCell key={item?.ParamName} align="center">
                                                    {item?.Metrics['Std.Dev']}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CCPReport;
