import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Label from '../../../atoms/Label/index';
import * as XLSX from 'xlsx';
import Axios from 'axios';
import FileDownload from 'js-file-download';
import TextInput from '../../../atoms/TextInput';
import DropDown from '../../../atoms/Dropdown';
import FormControl from '@mui/material/FormControl';
import Attachments from '../../../atoms/ExcelAttachments';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import ACGButton from '../../../atoms/Button';
import useGetState from '../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../constants/apiConstants';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../store/slice';

export default function CreateForm(props: any) {
  const dispatch = useDispatch()
  const [templateError, setTemplateError] = useState<boolean>(false);
  const [isSetFile, setIsSetFile] = useState<any>([]);
  const [templateSuccess, setTemplateSuccess] = useState(false);
  const [sheetData, setSheetData] = useState<any>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>([]);
  const [sheetResult, setSheetResult] = useState<any>([]);
  const state = useGetState();
  const [isLoading, setIsLoading] = useState(false);
  const token = state?.[STORE_KEYS.USER_DTL].token;
  const getLoggedUserId = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;

  const options = {
    AD_SUCCESS: {
      message: `Golden Batch Parameter Limit Update loaded successfully!`,
      open: true,
      type: 'success'
    },
    AD_FAIL: {
      message: `Filed to load Parameter Limit Update`,
      open: true,
      type: 'remark'
    }
  };

  const loadGoldenBatchBQSParameters = (type: any) => {
    try {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.API_POST_GOLDENBATCH_QA_BQS,
            reqObj: {
              "LoggedUserKey": getLoggedUserId,
              "RecipeKey": props.goldenRecipeKey,
              "BatchKey": props.selectedRow?.BatchKey,
              "GBParamConfigJason": sheetData,
              "AddEditActivity": type
            }
          },
          uniqueScreenIdentifier: { qaBQS: true },
          storeKey: STORE_KEYS.API_POST_GOLDENBATCH_QA_BQS
        })
      );

    }
    catch (err: any) {
      handleSnackbarError(err);
      props.drawerClose();
      props.setCurrTab(1);
    }
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_POST_GOLDENBATCH_QA_BQS]?.qaBQS) {
      props.setCurrTab(2);
      props.setFilteredGoldenRecipeKey(props.goldenRecipeKey);
      props.setSnackbarOpts(options.AD_SUCCESS);
      props.drawerClose();
      props.getQualityAttributesBQS(props.goldenRecipeKey);
      props.getGoldenBatchListBQS();
      props.setClearGBLimitFilter(true);
      const response = state?.[STORE_KEYS.API_POST_GOLDENBATCH_QA_BQS]?.body?.data;
      setSheetResult(response);
      setTemplateSuccess(false);
      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.API_POST_GOLDENBATCH_QA_BQS,
          uniqueScreenIdentifier: {
            qaBQS: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.API_POST_GOLDENBATCH_QA_BQS]?.qaBQS]);

  const loadGoldenBatchFBEParameters = (type: any) => {
    try {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.API_POST_GOLDENBATCH_QA_FBE,
            reqObj: {
              "LoggedUserKey": getLoggedUserId,
              "CustProductKey": props.selectedRow?.CustProductKey,
              "SubProcessKey": props.selectedRow?.SubProcessKey,
              "GBFBEParamConfigJason": sheetData,
              "AddEditActivity": type
            }
          },
          uniqueScreenIdentifier: { qaFBE: true },
          storeKey: STORE_KEYS.API_POST_GOLDENBATCH_QA_FBE
        })
      );

    }
    catch (err: any) {
      handleSnackbarError(err);
      props.drawerClose();
      props.setCurrTab(1);
    }
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_POST_GOLDENBATCH_QA_FBE]?.qaFBE) {
      props.setCurrTab(2);
      props.setSnackbarOpts(options.AD_SUCCESS);
      props.drawerClose();
      props.getQualityAttributesBQS(props.goldenRecipeKey);
      props.getGoldenBatchListFBE();
      props.setClearGBLimitFilter(true);
      const response = state?.[STORE_KEYS.API_POST_GOLDENBATCH_QA_FBE]?.body?.data;
      setSheetResult(response);
      setTemplateSuccess(false);
      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.API_POST_GOLDENBATCH_QA_FBE,
          uniqueScreenIdentifier: {
            qaFBE: true
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.API_POST_GOLDENBATCH_QA_FBE]?.qaFBE]);

  const handleSnackbarError = (err: any) => {
    const errorMsg = err?.message || 'Internal Server error';
    const snackbarError = {
      message: errorMsg,
      type: 'reject',
      open: true
    };
    props.setSnackbarOpts(snackbarError);
  };

  useEffect(() => {
    if (state?.err) {
      handleSnackbarError(state?.err);
      dispatch(resetErr());
    }
  }, [state?.err]);

  const uploadFile = (f: any) => {
    setSheetResult([]);
    const reader = new FileReader();
    if (reader.readAsBinaryString) {
      reader.onload = (e) => {
        if (props.machineModelName.includes("BQS")) {
          processExcelBQS(reader.result);
        } else {
          processExcelFBE(reader.result);
        }

      };
      reader.readAsBinaryString(f[0]);
    }
  }

  const processExcelBQS = (data: any) => {
    const allRows: any = [];
    let isError: boolean = false;
    const workbook = XLSX.read(data, { type: 'binary' });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
    excelRows.map(async (item: any) => {
      if (Object.keys(item).length !== 4) {
        isError = true;
        return
      } else {
        const data = Object.values(item)
        const obj = {
          ParamName: data[0],
          OptimumMin: data[1],
          OptimumMax: data[2],
          LimitType: data[3]
        }
        await allRows.push(obj);
      }
    })
    if (isError) {
      setTemplateError(true);
      setSelectedTemplate([]);
    } else {
      setTemplateError(false);
      setSelectedTemplate([]);
      setTemplateSuccess(true);
      setSheetData(allRows);
    }
  }

  const processExcelFBE = (data: any) => {
    const allRows: any = [];
    let isError: boolean = false;
    const workbook = XLSX.read(data, { type: 'binary' });
    workbook.SheetNames.map((i: any) => {
      const paramList: any = []
      const excelRows = XLSX.utils.sheet_to_json(workbook.Sheets[i]);
      excelRows.map(async (item: any) => {
        if (Object.keys(item).length !== 4) {
          isError = true;
          return
        } else {
          const data = Object.values(item)

          const obj = {
            ParamName: data[0],
            OptimumMin: data[1],
            OptimumMax: data[2],
            LimitType: data[3]
          }
          paramList.push(obj);
        }
      })
      allRows.push({ "PhaseName": i, "ParamList": paramList })
    })
    if (isError) {
      setTemplateError(true);
      setSelectedTemplate([]);
    } else {
      setTemplateError(false);
      setSelectedTemplate([]);
      setTemplateSuccess(true);
      setSheetData(allRows.splice(-1));
    }
  }

  const renderButtons = () => {
    return (
      <div className={'btns noWrap'} style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <ACGButton
          name={'Cancel'}
          handleClick={props.drawerClose}
          secondary={true}
          className={'first-btn'}
          type="button"
          variant="contained"
        />
        <ACGButton
          name={'Submit'}
          handleClick={() => {
            if (props.machineModelName.includes('BQS')) {
              loadGoldenBatchBQSParameters('Add');
            } else {
              loadGoldenBatchFBEParameters('Add')
            }
          }}
          type="submit"
          className="second-btn"
          variant="contained"
          disabled={!templateSuccess ? true : false}
          isLoading = {isLoading}
        />
      </div>
    );
  };

  return (
    <Container className="createReport">
      <Box>
        <div >
          <FormControl fullWidth>
            <Label label="Golden Recipe &nbsp; *" className={'CMlabelDiv'} />

            <TextInput
              fullWidth
              id="outlined-basic"
              sx={{ margin: '0px', padding: '2px' }}
              value={props.selectedRow?.RecipeName}
              disabled
            />
            {
              !props?.machineModelName.includes("BQS") ?
                <>
                  <Label label="Product Name &nbsp; *" className={'CMlabelDiv'} />

                  <TextInput
                    fullWidth
                    id="outlined-basic"
                    sx={{ margin: '0px', padding: '2px' }}
                    value={props.selectedRow?.ProductName}
                    disabled
                  />
                  <Label label="Sub Process Name &nbsp; *" className={'CMlabelDiv'} />

                  <TextInput
                    fullWidth
                    id="outlined-basic"
                    sx={{ margin: '0px', padding: '2px' }}
                    value={props.selectedRow?.SubProcessName}
                    disabled
                  />
                </>
                :
                null
            }

            <Label label="Download Template" className={'CMlabelDiv'} />
            <Button sx={{ width: "100px" }} >
              <a 
              href={"https://stbtffuturednadlsqa.blob.core.windows.net/smartconnected-files/BQS_Limit_Config_GB_Template.xlsx?sp=racwle&st=2024-07-09T13:07:26Z&se=2025-07-08T21:07:26Z&spr=https&sv=2022-11-02&sr=c&sig=9xfDXquPJRY5MEOIawH6IuvF%2BMYHGivOGHpMn4xYZyo%3D"} 
              download >
                <DownloadIcon style={{ width: "30px", height: "30px", color: "#ffffff" }} />
              </a>
            </Button>

            <Label label="Upload Template" className={'CMlabelDiv'} />
            <Attachments
              viewOnly={false}
              setSelectedTemplate={setSelectedTemplate}
              isSetFile={isSetFile}
              uploadFile={uploadFile}
              type={['.xlsx', '.xls']}
              templateError={templateError}
              setTemplateError={setTemplateError}
              selectedTemplate={selectedTemplate}
              templateSuccess={templateSuccess}
            />
          </FormControl>
        </div>
      </Box>
      <Grid style={{ display: "flex", marginTop: 20 }}>
        {renderButtons()}
      </Grid>

    </Container>
  );
}