import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Container, Card, Grid } from '@mui/material';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import DropDown from '../../../../atoms/Dropdown';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import useGetState from '../../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import notificationService from '../../notificationService';
import GroupMembers from './groupMembers';
import ACGButton from '../../../../atoms/Button';
import AlertBox from '../../../../atoms/AlertBox';
import { useDispatch } from 'react-redux';
import { executeACGAction } from '../../../../store/slice';

export default function LoadMembers(props: any) {
    const dispatch=useDispatch()
    const [customerKey, setCustomerKey] = useState('');
    const [userType, setUserType] = useState("portal-user");
    const [userList, setUserList] = useState<any>([]);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [userKey, setUserKey] = useState('');
    const [errormessage, setErrormessage] = useState('Invalid Email id');
    const [groupMemberList, setGroupMemberList] = useState([]);
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [rmUser, setRmUser] = useState(false);
    const [RNVKey, setRNVKey] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [validDomain, setValidDomain] = useState<any>(['acg-world.com','scitechcentre.com']);

    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const getLoggedUserId = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;

    const handleChange = (event: any) => {
        const data: any = userList?.find((i: any) => i?.code === event.target.value)
        setUserKey(event.target.value);
        setUserName(data?.name);
        setUserEmail(data?.email);
    };

    const handleChangeUserType = (event: any) => {
        setUserType(event.target.value);
        setUserEmail('');
        setUserName('');
        setUserKey('');
        setInvalidEmail(false);
    };

    const reset = (args: any) => {
        setUserEmail('');
        setUserName('');
        setUserKey('');
        setUserType('portal-user');
        if (args) props.setView(false);
        // props.setDeleteGrp(true);
        setInvalidEmail(false);
    }
    console.log(props.notificationData)
    const editableData = props.notificationData?.find((i: any) => i.NotifGrpKey === props.nKey);
    useEffect(() => {
        const body = {
            userId: getLoggedUserId,
            customerKey: editableData?.CustomerKey,
            token: token
        }
        notificationService.getPortalUserList(body)
            .then((response: any) => {
                const result = response?.data?.message[0];
                const filteredUsers: any = [];
                result[0]?.map((i: any) => {
                    const check = groupMemberList.some((j: any) => i.EmailId === j.EmailId)
                    if (!check) {
                        filteredUsers.push(i);
                    }
                });
                setUserList([
                    ...filteredUsers.map((item: any) => ({ code: item.UserKey, name: item.UserName, email: item.EmailId }))
                ]);
            })
            .catch((err) => {
                console.log(err);
            });
        fetchGroupMember();
    }, []);

    const fetchGroupMember = () => {
        const body = {
            userId: getLoggedUserId,
            grpKey: props.nKey,
            token: token
        }
        notificationService.getGroupMemberList(body)
            .then((response: any) => {
                const result = response?.data?.message[0];
                setGroupMemberList(result[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const closeRmBx = () => {
        setRemoveBxOpen(false);
    };
    const options = {
        AD_SUCCESS: {
            message: `Member added successfully!`,
            open: true,
            type: 'success'
        },
        AD_FAIL: {
            message: `Retry !`,
            open: true,
            type: 'remark'
        },
        RM_SUCCESS: {
            message: 'User Deleted!',
            open: true,
            type: 'success'
        },
    };

    const addMember = () => {
        const body = {
            userId: getLoggedUserId,
            grpKey: props.nKey,
            userKey: userKey,
            userEmail: userEmail,
            userName: userName,
            type: 'Add',
            token: token
        }
        notificationService.addMemberToGroup(body)
            .then((response) => {
                if (response.status === 200) {
                    reset(false);
                    fetchGroupMember();
                    props.fetchNotificationGroups(true);
                    props.setCurrTab(2);
                    props.setSnackbarOpts(options.AD_SUCCESS);
                } else {
                    props.setCurrTab(2);
                    props.setSnackbarOpts(options.AD_FAIL);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    let yesDisabled: boolean;
    if (userType === 'portal-user') {
        yesDisabled = userKey === '' || userEmail === '' || userName === '' ? true : false;
    } else {
        yesDisabled = userEmail === '' || userName === '' || invalidEmail ? true : false;
    }

    const deleteClicked = (args: any) => {
        setRNVKey(args);
        setRemoveBxOpen(true);
    }

    const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        const alphabeticRegex = /^[a-zA-Z0-9]*$/;

        if (inputValue.match(alphabeticRegex) || inputValue === '') {

            setUserName(inputValue);
        }
    }

    const validateEmail = (e: any) => {
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if (e.match(mailformat)) {
            console.log("test", e, validDomain)
            const domain = e?.split('@')[1];
            if (validDomain.includes(domain)) {
                setInvalidEmail(false);
                const check = groupMemberList.some((j: any) => j.EmailId === e);
                if (check) {
                    setInvalidEmail(true);
                    setErrormessage('Email Id already exists');
                } else {
                    setInvalidEmail(false);
                }
            } else {
                setErrormessage('Invalid Email domain');
                setInvalidEmail(true);
            }
        } else {
            setErrormessage('Invalid Email id');
            setInvalidEmail(true);
        }
    }

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'} style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={props.drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { addMember() }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={yesDisabled ? true : false}
                    isLoading = {isLoading}
                />
            </div>
        );
    };

    useEffect(() => {
        if (rmUser) {
            closeRmBx();
            const body = { userId: getLoggedUserId, key: RNVKey, table: 'NotifGrpDtls', token: token }
            notificationService.deleteNotificationGM(body)
                .then((response) => {
                    if (response.status === 200) {
                        props.setSnackbarOpts(options.RM_SUCCESS);
                        props.fetchNotificationGroups(true);
                        fetchGroupMember();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            setRmUser(false);
        }
    }, [rmUser]);

    useEffect(() => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_CLIENT_DROPDOWN
                },
                storeKey: STORE_KEYS.CLIENT_DROPDOWN,
                uniqueScreenIdentifier: {
                    isLoading: true
                }
            })
        );
    }, [])

    useEffect(() => {
        state?.[STORE_KEYS.CLIENT_DROPDOWN]?.body?.data?.map((item: any) => {
            if (item.DomainName) {
                setValidDomain((prev: any) => [...prev, item.DomainName])
            }
        })
    }, [state?.[STORE_KEYS.CLIENT_DROPDOWN]?.body?.data])

    return (
        <Container className="createReport">
            <Box sx={{ minWidth: 120, display: 'flex', flexDirection: 'column', height: '93vh', justifyContent: 'space-between' }}>
                <div className='all-labelcls'>
                    <FormControl fullWidth>
                        <Label label="Select User type" className={'CMlabelDiv'} />
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group" className='radiolabls'

                        >
                            <FormControlLabel value="portal-user" control={<Radio onChange={handleChangeUserType} checked={userType === 'portal-user' ? true : false} />} label="Portal user" />
                            <FormControlLabel value="new-user" control={<Radio onChange={handleChangeUserType} checked={userType === 'new-user' ? true : false} />} label="New user" />
                        </RadioGroup>
                        {userType === "portal-user" ?
                            <DropDown
                                onChange={handleChange}
                                className="inputDiv"
                                name="dropdownModel"
                                items={userList}
                                placeHolder="Select User Name"
                                value={userKey}
                            />
                            : null}
                        <Label label="User Name" className={'CMlabelDiv'} />

                        <TextInput id="outlined-basic"
                            sx={{ marginBottom: "20px" }}
                            inputProps={{ maxLength: 50 }}
                            fullWidth
                            disabled={userType === "portal-user" ? true : false}
                            onChange={handleUserNameChange}
                            placeholder="Please Enter User Name"
                            value={userName}
                        />
                        <Label label="Email Id" className={'CMlabelDiv'} />
                        <TextInput
                            id="outlined-basic"
                            variant="outlined"
                            sx={{ marginBottom: "20px" }}
                            fullWidth
                            inputProps={{ maxLength: 50 }}
                            value={userEmail}
                            onChange={(e) => { setUserEmail(e.target.value); validateEmail(e.target.value); }}
                            disabled={userType === "portal-user" ? true : false}
                            helperText={invalidEmail ? errormessage : null}
                            error={invalidEmail}
                            placeholder="Please Enter Email Id"
                        />
                    </FormControl>
                    <div style={{ display: 'flex', alignItems: 'center' }}><Label label="Group members:&nbsp;" className={'CMlabelDiv'} /><div className="countval">{" " + groupMemberList.length}</div></div>
                    <div style={{ scrollBehavior: 'auto', display: 'flex', flexDirection: 'column' }}>
                        <GroupMembers groupMemberList={groupMemberList} fetchGroupMember={fetchGroupMember} deleteClicked={(args: any) => { deleteClicked(args) }} />
                    </div>
                </div>
            </Box>
            <Grid style={{ display: "flex" }}>
                {renderButtons()}
            </Grid>
            <AlertBox
                isOpen={removeBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                rightBtnPress={() => setRmUser(true)}
                modalHeader="Remove User"
                modalContent="Are you sure you want to remove user group?"
            />
        </Container>
    );
}