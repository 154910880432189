import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import Label from '../../../atoms/Label/index';
import TextInput from '../../../atoms/TextInput';
import DownArrow from '../../../../assets/down-arrow.svg';
import DropDown from '../../../atoms/Dropdown';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CustomDatePicker from '../../../atoms/CustomDatePicker';
import ACGButton from '../../../atoms/Button';
import useGetState from '../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../constants/apiConstants';
import "../cm.scss";
import { Grid } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function CreateForm(props: any) {
  const [isDisabled, setIsDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false);
  const yesDisabled = !props.filteredModule.length || !props.filteredCustomer.length ? true : false;
  const DropDownIcon = (prop: any) => (
    <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
  );
  const submitHandler = () => {
    props.drawerClose();
    props.setAppliedFilters(true)
    props.currTab === 0 ? props.getAllNotifications(false) : props.currTab === 2 ? props.fetchNotificationGroups(false) : props.getHistoricalNotifications(false);
  }
  const renderButtons = () => {
    return (
      <div className={'btns noWrap'} style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <ACGButton
          name={'Cancel'}
          handleClick={props.drawerClose}
          secondary={true}
          className={'first-btn'}
          type="button"
          variant="contained"
        />
        <ACGButton
          name={'Submit'}
          handleClick={() => { submitHandler() }}
          type="submit"
          className="second-btn"
          variant="contained"
          isLoading = {isLoading}
        />
      </div>
    );
  };

  useEffect(() => {
    if (props.currTab === 0) {
      if (props.filteredReportType != -1 && props.filteredSeverity.length && props.filteredMachines.length) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    }
    else if (props.currTab === 2) {
      if (props.filteredModule.length && props.filteredCustomer.length) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    } else {
      if (props.historyFilteredReportType != -1 && props.historyFilteredSeverity.length && props.historyFilteredMachines.length) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    }
  }, [props])

  return (
    <Container className="createReport">
      <Box>
        <div className='formwrap all-labelcls'>
          <FormControl fullWidth>
            {props.currTab === 0 ?
              <>
                <Label label="Modules" className={'labelDiv'} />
                <DropDown
                  onChange={(e) => props.handleChangeDropdownNotifications(e)}
                  className="inputDiv"
                  name="dropdownModel"
                  items={props.ruleTypes}
                  placeHolder="Select Modules"
                  value={props.filteredReportType}
                />
                <Label label="Severity" className={'CMlabelDiv'} />
                <FormControl sx={{ width: '100%' }}>
                  {/* {props.filteredSeverity?.length > 0 ? null : (
                      <InputLabel
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '13px',
                          paddingTop: '0px',
                          paddingLeft: '12px',
                          color: '#b1c2df',
                          lineHeight: '1',
                          opacity: '0.7'
                        }}
                        id="demo-multiple-checkbox-label"
                      >
                        Select Severity
                      </InputLabel>
                    )} */}
                  <Select
                    IconComponent={DropDownIcon}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    label=""
                    multiple
                    value={props.filteredSeverity}
                    sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                    placeholder='Please select machines'
                    // input={<OutlinedInput />}
                    // renderValue={(selected) => selected.map((x: any) => x.SeverityName).join(', ')}
                    MenuProps={MenuProps}

                    displayEmpty={true}
                    renderValue={value => value?.length ? value.map((x: any) => x.SeverityName).join(', ') : 'Select Severity'}
                  >
                    {props.severity?.map((i: any) => (
                      <MenuItem value={i} onClick={() => props.handleSeverityNotifications(i)}>
                        <Checkbox
                          checked={
                            props.filteredSeverity?.findIndex((item: any) => item.SeverityKey === i.SeverityKey) >= 0
                          }

                        />
                        <ListItemText primary={i.SeverityName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Label label="Plants" className={'labelDiv'} />
                <DropDown
                  onChange={(e) => props.handleNotificationsPlantsDropdown(e)}
                  className="inputDiv"
                  name="dropdownModel"
                  items={props.notiFilterPlantList}
                  placeHolder="Select Plant"
                  value={props.notifilteredPlant}
                />
                <Label label="Machines" className={'CMlabelDiv'} />
                <FormControl sx={{ width: '100%' }}>
                  {/* {props.filteredMachines?.length > 0 ? null : (
                    <InputLabel
                      style={{
                        fontFamily: 'Montserrat',
                        fontSize: '13px',
                        paddingTop: '0px',
                        paddingLeft: '12px',
                        color: '#b1c2df',
                        lineHeight: '1',
                        opacity: '0.7'
                      }}
                      id="demo-multiple-checkbox-label"
                    >
                      Select Machines
                    </InputLabel>
                  )} */}
                  <Select
                    IconComponent={DropDownIcon}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    label=""
                    multiple
                    value={props.filteredMachines}
                    sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                    placeholder='Please select machines'
                    // input={<OutlinedInput />}
                    // renderValue={(selected) => selected.map((x: any) => x.MachineName).join(', ')}
                    MenuProps={MenuProps}
                    displayEmpty={true}
                    renderValue={value => value?.length ? value.map((x: any) => x.MachineName).join(', ') : 'Select Machines'}

                  >
                    {props.machines?.map((i: any) => (
                      <MenuItem value={i} onClick={() => props.handleMachinesNotifications(i)}>
                        <Checkbox
                          checked={
                            props.filteredMachines?.findIndex((item: any) => item.MachineKey === i.MachineKey) >= 0
                          }

                        />
                        <ListItemText primary={i.MachineName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
              :
              props.currTab === 2 ?
                <>
                <div className='labelfixed'>
                  <Label label="Module Name" className={'CMlabelDiv'} />
                  <FormControl sx={{ width: '100%' }}>
                    {props.modulesData?.length > 0 ? null : (
                      <InputLabel
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '13px',
                          paddingTop: '0px',
                          paddingLeft: '12px',
                          color: '#b1c2df',
                          lineHeight: '1',
                          opacity: '0.7'
                        }}
                        id="demo-multiple-checkbox-label"
                      >
                        Select Module
                      </InputLabel>
                    )}
                    <Select
                      IconComponent={DropDownIcon}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      label=""
                      multiple
                      value={props.filteredModule}
                      sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                      placeholder='Please select machines'
                      // input={<OutlinedInput />}
                      renderValue={(selected) => selected.map((x: any) => x.name).join(', ')}
                      MenuProps={MenuProps}

                    >
                      {props.modulesData?.map((i: any) => (
                        <MenuItem value={i} onClick={() => props.handleModulesName(i)}>
                          <Checkbox
                            checked={
                              props.filteredModule?.findIndex((item: any) => item.code === i.code) >= 0
                            }

                          />
                          <ListItemText primary={i.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </div>
                  <div className='labelfixed'>
                  <Label label="Customer Name" className={'CMlabelDiv'} />
                  <FormControl sx={{ width: '100%' }}>
                    {props.customersData?.length > 0 ? null : (
                      <InputLabel
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '13px',
                          paddingTop: '0px',
                          paddingLeft: '12px',
                          color: '#b1c2df',
                          lineHeight: '1',
                          opacity: '0.7'
                        }}
                        id="demo-multiple-checkbox-label"
                      >
                        Select Customer
                      </InputLabel>
                    )}
                    <Select
                      IconComponent={DropDownIcon}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      label=""
                      multiple
                      value={props.filteredCustomer}
                      sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                      placeholder='Please select machines'
                      // input={<OutlinedInput />}
                      renderValue={(selected) => selected.map((x: any) => x.name).join(', ')}
                      MenuProps={MenuProps}

                    >
                      {props.customersData?.map((i: any) => (
                        <MenuItem value={i} onClick={() => props.handleCustomersName(i)}>
                          <Checkbox
                            checked={
                              props.filteredCustomer?.findIndex((item: any) => item.code === i.code) >= 0
                            }

                          />
                          <ListItemText primary={i.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </div>
                  <div className='labelfixed'>
                  <Label label="Plant Name" className={'CMlabelDiv'} />
                  <FormControl sx={{ width: '100%' }}>
                    {props.notificationGroupPlantList?.length > 0 ? null : (
                      <InputLabel
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '13px',
                          paddingTop: '0px',
                          paddingLeft: '12px',
                          color: '#b1c2df',
                          lineHeight: '1',
                          opacity: '0.7'
                        }}
                        id="demo-multiple-checkbox-label"
                      >
                        Select Plant
                      </InputLabel>
                    )}
                    <Select
                      IconComponent={DropDownIcon}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      label=""
                      multiple
                      value={props.filteredPlant}
                      sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                      placeholder='Please select machines'
                      // input={<OutlinedInput />}
                      renderValue={(selected) => selected.map((x: any) => x.name).join(', ')}
                      MenuProps={MenuProps}

                    >
                      {props.notificationGroupPlantList?.map((i: any) => (
                        <MenuItem value={i} onClick={() => props.handlePlantsName(i)}>
                          <Checkbox
                            checked={
                              props.filteredPlant?.findIndex((item: any) => item.code === i.code) >= 0
                            }

                          />
                          <ListItemText primary={i.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </div>
                </>
                :
                <>
                  <Label label="Start Date" className={'labelDiv'} />
                  <CustomDatePicker
                    name="startDate"
                    value={props.inDate}
                    className="inputDiv"
                    onChange={(val: any) => {
                      props.setInDate(val);
                    }}
                    inputFormat="dd/MM/yyyy"
                    placeholder="Select Start Date"
                    maxDate={new Date()}
                    minDate={new Date(new Date().setMonth(new Date().getMonth() - 3))}
                  />
                  <Label label="End Date" className={'labelDiv'} />
                  <CustomDatePicker
                    name="endDate"
                    value={props.endDate}
                    className="inputDiv"
                    onChange={(val: any) => {
                      props.setEndDate(val);
                    }}
                    inputFormat="dd/MM/yyyy"
                    placeholder="Select Start Date"
                    maxDate={new Date()}
                    minDate={new Date(props.inDate)}
                  />
                  <Label label="Modules" className={'labelDiv'} />
                  <DropDown
                    onChange={(e) => props.handleChangeDropdown(e)}
                    className="inputDiv"
                    name="dropdownModel"
                    items={props.ruleTypes}
                    placeHolder="Select Modules"
                    value={props.historyFilteredReportType}
                  />
                  <Label label="Severity" className={'CMlabelDiv'} />
                  <FormControl sx={{ width: '100%' }}>
                    {/* {props.historyFilteredSeverity?.length > 0 ? null : (
                      <InputLabel
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '13px',
                          paddingTop: '0px',
                          paddingLeft: '12px',
                          color: '#b1c2df',
                          lineHeight: '1',
                          opacity: '0.7'
                        }}
                        id="demo-multiple-checkbox-label"
                      >
                        Select Severity
                      </InputLabel>
                    )} */}
                    <Select
                      IconComponent={DropDownIcon}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      label=""
                      multiple
                      value={props.historyFilteredSeverity}
                      sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                      placeholder='Please select machines'
                      // input={<OutlinedInput />}
                      // renderValue={(selected) => selected.map((x: any) => x.SeverityName).join(', ')}
                      MenuProps={MenuProps}
                      displayEmpty={true}
                      renderValue={value => value?.length ? value.map((x: any) => x.SeverityName).join(', ') : 'Select Severity'}

                    >
                      {props.severity?.map((i: any) => (
                        <MenuItem value={i} onClick={() => props.handleSeverity(i)}>
                          <Checkbox
                            checked={
                              props.historyFilteredSeverity?.findIndex((item: any) => item.SeverityKey === i.SeverityKey) >= 0
                            }

                          />
                          <ListItemText primary={i.SeverityName} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Label label="Plants" className={'labelDiv'} />
                <DropDown
                  onChange={(e) => props.handleNotificationsPlantsDropdown(e)}
                  className="inputDiv"
                  name="dropdownModel"
                  items={props.notiFilterPlantList}
                  placeHolder="Select Plant"
                  value={props.notifilteredPlant}
                />
                  <Label label="Machines" className={'CMlabelDiv'} />
                  <FormControl sx={{ width: '100%' }}>
                    {/* {props.historyFilteredMachines?.length > 0 ? null : (
                      <InputLabel
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '13px',
                          paddingTop: '0px',
                          paddingLeft: '12px',
                          color: '#b1c2df',
                          lineHeight: '1',
                          opacity: '0.7'
                        }}
                        id="demo-multiple-checkbox-label"
                      >
                        Select Machines
                      </InputLabel>
                    )} */}
                    <Select
                      IconComponent={DropDownIcon}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      label=""
                      multiple
                      value={props.historyFilteredMachines}
                      sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                      placeholder='Please select machines'
                      // input={<OutlinedInput />}
                      // renderValue={(selected) => selected.map((x: any) => x.MachineName).join(', ')}
                      MenuProps={MenuProps}
                      displayEmpty={true}
                      renderValue={value => value?.length ? value.map((x: any) => x.MachineName).join(', ') : 'Select Machines'}

                    >
                      {props.machines?.map((i: any) => (
                        <MenuItem value={i} onClick={() => props.handleMachines(i)}>
                          <Checkbox
                            checked={
                              props.historyFilteredMachines?.findIndex((item: any) => item.MachineKey === i.MachineKey) >= 0
                            }

                          />
                          <ListItemText primary={i.MachineName} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
            }
          </FormControl>
        </div>
      </Box>
      <Grid style={{ display: "flex", marginTop: 20 }}>
        {renderButtons()}
      </Grid>
    </Container>
  );
};
