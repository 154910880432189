import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import HelpIcon from '@mui/icons-material/Help';
import { Grid, Typography, Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { QueryBuilderMaterial } from '@react-querybuilder/material';
import { useEffect, useRef, useState } from 'react';
import QueryBuilder, { defaultOperators, formatQuery } from 'react-querybuilder';
import DownArrow from '../../../../../assets/down-arrow.svg';
import ACGButton from '../../../../atoms/Button';
import DropDown from '../../../../atoms/Dropdown';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import { STORE_KEYS } from '../../../../constants/apiConstants';
import useGetState from '../../../../utils/hooks/useGetState';
import "../../cm.scss";
import RuleEngineService from '../../ruleEngineServices';
import "./style.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const muiTheme = createTheme();
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const initialQuery = { combinator: 'and', rules: [] };
export default function CreateForm(props: any) {
  const [query, setQuery] = useState<any>(initialQuery);
  const [ruleName, setRuleName] = useState('');
  const [ruleDesc, setRuleDesc] = useState('');
  const [editing, setEditing] = useState(props.customKey !== '' ? true : false);
  const [modelsData, setModelsData] = useState<any[]>([]);
  const [presetRulesData, setPresetRulesData] = useState([]);
  const [ruleEnginePresetKey, setRuleEnginePresetKey] = useState('');
  const [machineNameData, setMachineNameData] = useState([]);
  const [machineNames, setMachineNames] = useState<any[]>([]);
  const [recipeData, setRecipeData] = useState([]);
  const [recipeNames, setRecipeNames] = useState<any[]>([]);
  const [customerKey, setCustomerKey] = useState('');
  const [machineModelKey, setMachineModelKey] = useState('');
  const [severityData, setSeverityData] = useState<any[]>([]);
  const [severityKey, setSeverityKey] = useState('');
  const [recommendedNotes, setRecommendedNotes] = useState('');
  const [durationData, setDurationData] = useState<any[]>([]);
  const [duration, setDuration] = useState('');
  const [notificationGroupsData, setNotificationGroupsData] = useState<any[]>([]);
  const [notificationNames, setNotificationNames] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [subject, setSubject] = useState('');
  const [notifyType, setNotifyType] = useState("P");
  const [groupNameData, setGroupNameData] = useState([]);
  const [groupKey, setGroupKey] = useState('');
  const [groupKeyNames, setGroupKeyNames] = useState<any[]>([]);
  const [parameterData, setParameterData] = useState([]);
  const [parameterNames, setParameterNames] = useState<any[]>([]);
  const [operators, setOperators] = useState([]);
  const [queryBuildData, setQueryBuildData] = useState([]);
  const [rangeDetails, setRangeDetails] = useState([]);
  const [customizedQuery, setCustomizedQuery] = useState('');
  const [alertType, setAlertType] = useState("Y");
  const [copyingRules, setCopyingRules] = useState<any[]>([]);
  const [copyingCustomKey, setCopyingCustomKey] = useState('');
  const [canCopy, setCanCopy] = useState<boolean>(false);
  const ref = useRef(null);
  const state = useGetState();
  const token = state?.[STORE_KEYS.USER_DTL].token;
  const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;

  useEffect(() => {
    RuleEngineService.getRuleEngineOperators({ token: token })
      .then((response: any) => {
        const result = response?.data?.message[0];
        setOperators(result[0]);
      })
      .catch((err) => {
        console.log(err);
      });

    RuleEngineService.getRuleEngineSeverityList({ token: token })
      .then((response: any) => {
        const result = response?.data?.message[0];
        setSeverityData([
          ...result[0].map((item: any) => ({ code: item.SeverityKey, name: item.SeverityName }))
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
    const body = { userId: loggedInUser, token: token }
    RuleEngineService.getRuleEngineTimeDuration(body)
      .then((response: any) => {
        const result = response?.data?.message[0];
        setDurationData([
          ...result[0].map((item: any) => ({ code: item.PresetCondTimeDurationKey, name: item.PresetCondTimeDurationName }))
        ]);
      })
      .catch((err) => {
        console.log(err);
      });

    RuleEngineService.getRuleEngineExsistingRules({ token: token, userId: loggedInUser })
      .then((response: any) => {
        const result = response?.data?.message[0];
        setCopyingRules([
          ...result[0].map((item: any) => ({ code: item.RuleEngKey, name: item.RuleName }))
        ]);
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);

  useEffect(() => {
    if ((editing && props.editableCustomData[0] && props.customKey !== '') || canCopy && props.editableCustomData.length > 0) {
      (async function () {
        setRuleName(props.editableCustomData[0]?.RuleName);
        setRuleDesc(props.editableCustomData[0]?.RuleDesc)
        setMachineNames([]);
        setRecipeNames([]);
        setNotificationNames([]);
        setAlertType(props.editableCustomData[0]?.IsInstantAlert);
        const reverseMKeys: any = [];
        let reverseRKeys: any = [];
        let reversPKeys: any = [];
        let test;
        setCustomerKey(props.editableCustomData[0]?.CustomerKey);
        const n = getModels(props.editableCustomData[0]?.CustomerKey);
        let r, rKeys: any, pKeys: any;
        setMachineModelKey(props.editableCustomData[0]?.MachineModelKey);
        setRuleEnginePresetKey(props.editableCustomData[0]?.RuleEngKey);
        const m = getMachineModelNameList(props.editableCustomData[0]?.CustomerKey, props.editableCustomData[0]?.MachineModelKey);
        if (props.editableCustomData[0]?.MachineKeys !== null) {
          const mKeys = (props.editableCustomData[0]?.MachineKeys).split(',');
          m.then((res: any) => res?.map((i: any, index: number) => {
            if (mKeys.includes(i.MachineKey.toString())) {
              reverseMKeys.push(i);
            }

          }
          ));
          setMachineNames(reverseMKeys);
        }
        r = getReciepeList(props.editableCustomData[0]?.CustomerKey, props.editableCustomData[0]?.MachineModelKey, reverseMKeys).then(res => { return res });
        r.then((result: any) => {
          if (props.editableCustomData[0]?.RecipeKeys !== null) {
            rKeys = (props.editableCustomData[0]?.RecipeKeys).split(',');
            result.map((k: any) => {
              if (rKeys.includes(k.RecipeKey.toString())) {
                reverseRKeys.push(k)
              }
            })
            setRecipeNames(reverseRKeys);
          }
        })

        getGroupName(props.editableCustomData[0]?.MachineModelKey);
        setGroupKeyNames([{ MachMdlParamGrpKey: -1, GroupName: "All" }]);
        const p = getParameters(props.editableCustomData[0]?.MachineModelKey, -1).then((result: any) => {
          pKeys = (props.editableCustomData[0]?.ParamCodes).split(',');
          result.map((k: any) => {
            if (pKeys.includes(k.ParamCode.toString())) {
              reversPKeys.push(k)
              handleChangeParameterName(k, result);
            }
          })
          setParameterNames(reversPKeys);
        });
        setSubject(props.editableCustomData[0]?.MailSubject);
        setSeverityKey(props.editableCustomData[0]?.SeverityKey);
        setRecommendedNotes(props.editableCustomData[0]?.RecommendedActions);
        setDuration(props.editableCustomData[0]?.NonInstantTimeDurationKey);
        const qry: any = await JSON.parse(props.editableCustomData[0]?.ConditionJson)
        setQuery(qry)
        setNotifyType(props.editableCustomData[0]?.AlertTypeCode)
        if (props.editableCustomData[0]?.MailNotifGrpKeys !== null) {

          const nKeys = (props.editableCustomData[0]?.MailNotifGrpKeys).split(','), reverseNKeys: any = [];
          n.then((res: any) => res?.map((i: any) => {
            if (nKeys.includes(i.RuleEngNotifGrpKey.toString())) {
              reverseNKeys.push(i)
            }
          }));
          setNotificationNames(reverseNKeys);
        }
      })()
    }
  }, [props.customKey, canCopy])
  useEffect(() => {
    if ((editing && props.editableCustomData[0] && props.customKey !== '') || canCopy && props.editableCustomData.length > 0) {
      const filteredArray = groupNameData.filter((item: any) => props?.editableCustomData?.[0]?.MachMdlParamGrpKeys.split(",").includes(String(item.MachMdlParamGrpKey)));
      setGroupKeyNames(filteredArray)
    }

  }, [props.customKey, groupNameData])

  useEffect(() => {
    if (props.customKey === '') {
      reset();
    }
  }, [props.customKey])

  useEffect(() => {
    if (query.rules.length === 0) {
      setQuery(initialQuery);
    }
    let qry = formatQuery(query, { format: 'sql', parseNumbers: true }), referenceQuery = formatQuery(query, { format: 'sql', parseNumbers: true });
    for (let i = 0; i < qry.length; i++) {
      if (qry[i] === 'D') {
        const actualName = parameterNames?.find((j: any) => (j.ParamCode === qry.substring(i, i + 5)))?.ParamName;
        referenceQuery = referenceQuery.replace(qry.substring(i, i + 5), actualName)
      }
    }

    setCustomizedQuery(referenceQuery);
  }, [query])

  const shouldRemove = (obj: any, value: any) => {
    return Object.values(obj).some((val: any) => {
      if (typeof (val) === "object") {
        val?.map((j: any, index: number) => {
          if (j.field === value) {
            if (val.length > 1) val?.splice(index, 1);
          }
        })

      }
    });
  }

  const clean = (object: any, item: any) => {
    if (props.customKey !== '') {
      const cleanedArray = object.map((subArray: any) => {
        return typeof (subArray) === "object" && subArray.filter((obj: any) => !shouldRemove(obj, item))
      }
      );
    }
  }

  const handleChangeNotifyType = (event: any) => {
    setNotifyType(event.target.value);
    setSubject('');
    setNotificationNames([]);
  };

  const handleChangeAlertType = (event: any) => {
    setAlertType(event.target.value);
  }

  const handleChange = (event: any) => {
    setNotificationNames([]);
    setMachineModelKey('');
    setPresetRulesData([]);
    setRuleEnginePresetKey('');
    setMachineNameData([]);
    setMachineNames([]);
    setRecipeData([]);
    setRecipeNames([]);
    setGroupNameData([]);
    setGroupKey('');
    setParameterData([]);
    setParameterNames([]);
    setQueryBuildData([]);
    setCustomerKey(event.target.value);
    getModels(event.target.value);
    setQuery(initialQuery);
  };

  const getModels = async (args: any) => {
    let finalResult;
    const body = { userId: loggedInUser, customerKey: args, token: token }
    RuleEngineService.getRuleEngineCustomerModels(body)
      .then((response: any) => {
        const result = response?.data?.message[0];
        setModelsData([
          ...result[0].map((item: any) => ({ code: item.MachineModelKey, name: item.MachineModelName }))
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
    await RuleEngineService.getRuleEngineCustomerNotificationGroups(body)
      .then((response: any) => {
        const result = response?.data?.message[0];
        setNotificationGroupsData(result && result[0]);
        finalResult = result[0];
      })
      .catch((err) => {
        console.log(err);
      });
    return finalResult;
  }

  const handleChangeSeverity = (event: any) => {
    setSeverityKey(event.target.value);
  };

  const handleChangeDuration = (event: any) => {
    setDuration(event.target.value);
  }

  const handleChangeModel = (id: any) => {
    setRuleEnginePresetKey('');
    setMachineNameData([]);
    setMachineNames([]);
    setRecipeData([]);
    setRecipeNames([]);
    setMachineModelKey(id.target.value);
    setGroupNameData([]);
    setGroupKey('');
    setParameterData([]);
    setParameterNames([]);
    setQueryBuildData([]);
    getMachineModelNameList(customerKey, id.target.value);
    getReciepeList(customerKey, id.target.value, [])
    getGroupName(id.target.value);
    setQuery(initialQuery);
    getParameters(id.target.value, -1);
  };

  const getGroupName = async (args: any) => {
    let finalResult;
    const body = { userId: loggedInUser, machineModelKey: args, token: token }
    RuleEngineService.getRuleEngineGroupNames(body)
      .then((response: any) => {
        const result = response?.data?.message[0];
        setGroupNameData(result[0]);
        finalResult = result[0];
      })
      .catch((err) => {
        console.log(err);
      });
    return finalResult;
  }

  const getMachineModelNameList = async (args1: any, args2: any) => {
    let finalResult;
    const body = { userId: loggedInUser, customerKey: args1 ? args1 : -1, machineModelKey: args2, token: token }
    await RuleEngineService.getMachineList(body)
      .then(async (response: any) => {
        const result = response?.data?.message[0];
        setMachineNameData(result[0]);
        finalResult = result[0];
      })
      .catch((err) => {
        console.log(err);
      });
    return finalResult;
  }

  const handleChangeMachineNames = (id: any) => {
    // setRecipeData([]);
    // setRecipeNames([]);
    let newSelected: any = [];
    if (props.customKey === '') {
      const selectedIndex = machineNames.indexOf(id);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(machineNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(machineNames.slice(1));
      } else if (selectedIndex === machineNames.length - 1) {
        newSelected = newSelected.concat(machineNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          machineNames.slice(0, selectedIndex),
          machineNames.slice(selectedIndex + 1)
        );
      }
    }
    else {
      const selectedIndex = machineNames.findIndex((t: any) => t.MachineKey === id.MachineKey);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(machineNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(machineNames.slice(1));
      } else if (selectedIndex === machineNames.length - 1) {
        newSelected = newSelected.concat(machineNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          machineNames.slice(0, selectedIndex),
          machineNames.slice(selectedIndex + 1)
        );
      }
    }
    setMachineNames(newSelected)

  }

  const getReciepeList = async (args1: any, args2: any, args3: any) => {
    const mKeys: any = [], reverseMKeys = [], tempArray = [];
    let finalResult;
    args3?.map((i: any) => mKeys.push(i.MachineKey))
    const body = { userId: loggedInUser, customerKey: args1 ? args1 : -1, machineModelKey: args2 ? args2 : -1, machineKeys: mKeys.length > 0 ? mKeys.toString() : -1, token: token }
    await RuleEngineService.getRecipeList(body)
      .then((response: any) => {
        const result = response?.data?.message[0];
        setRecipeData(result[0]);
        finalResult = result[0];
      })
      .catch((err) => {
        console.log(err);
      });
    return finalResult;
  }

  const handleChangeRecipeNames = (id: any) => {
    let newSelected: any = [];
    if (props.customKey === '') {
      const selectedIndex = recipeNames.indexOf(id);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(recipeNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(recipeNames.slice(1));
      } else if (selectedIndex === recipeNames.length - 1) {
        newSelected = newSelected.concat(recipeNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          recipeNames.slice(0, selectedIndex),
          recipeNames.slice(selectedIndex + 1)
        );
      }
    }
    else {
      const selectedIndex = recipeNames.findIndex((t) => t.RecipeKey === id.RecipeKey);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(recipeNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(recipeNames.slice(1));
      } else if (selectedIndex === recipeNames.length - 1) {
        newSelected = newSelected.concat(recipeNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          recipeNames.slice(0, selectedIndex),
          recipeNames.slice(selectedIndex + 1)
        );
      }
    }
    setRecipeNames(newSelected);
  }

  const handleChangeGroupName = (id: any) => {
    let newSelected: any = [];
    if (props.customKey === '') {
      const selectedIndex = groupKeyNames.indexOf(id);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(groupKeyNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(groupKeyNames.slice(1));
      } else if (selectedIndex === groupKeyNames.length - 1) {
        newSelected = newSelected.concat(groupKeyNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          groupKeyNames.slice(0, selectedIndex),
          groupKeyNames.slice(selectedIndex + 1)
        );
      }
    }
    else {
      const selectedIndex = groupKeyNames.findIndex((t) => t.MachMdlParamGrpKey === id.MachMdlParamGrpKey);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(groupKeyNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(groupKeyNames.slice(1));
      } else if (selectedIndex === groupKeyNames.length - 1) {
        newSelected = newSelected.concat(groupKeyNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          groupKeyNames.slice(0, selectedIndex),
          groupKeyNames.slice(selectedIndex + 1)
        );
      }
    }

    setGroupKeyNames(newSelected);
    setParameterData([]);
    // setParameterNames([]);
    // setQueryBuildData([]);
    console.log("===>", machineModelKey, id)
    getParameters(machineModelKey, id.MachMdlParamGrpKey);
    // setQuery(initialQuery);
  }

  const getParameters = async (args: any, args2: any) => {
    let finalResult;
    const body = { userId: loggedInUser, machineModelKey: args, groupKey: args2, token: token }
    await RuleEngineService.getParameterList(body)
      .then(async (response: any) => {
        const result = response?.data?.message[0];
        setParameterData(result && result[0]);
        finalResult = result[0];
      })
      .catch((err) => {
        console.log(err);
      });
    return finalResult;
  }

  const handleChangeParameterName = (id: any, args: any) => {
    let newSelected: any = [];
    if (props.customKey === '') {
      const selectedIndex = parameterNames.indexOf(id);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(parameterNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(parameterNames.slice(1));
      } else if (selectedIndex === parameterNames.length - 1) {
        newSelected = newSelected.concat(parameterNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          parameterNames.slice(0, selectedIndex),
          parameterNames.slice(selectedIndex + 1)
        );
      }
    }
    else {
      const selectedIndex = parameterNames.findIndex((t) => t.MMParamKey === id.MMParamKey);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(parameterNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(parameterNames.slice(1));
      } else if (selectedIndex === parameterNames.length - 1) {
        newSelected = newSelected.concat(parameterNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          parameterNames.slice(0, selectedIndex),
          parameterNames.slice(selectedIndex + 1)
        );
      }
    }
    setParameterNames(newSelected);
    if (newSelected.length === 0) setQuery(initialQuery);
  }

  const fetchRangeDetails = (body: any) => {

    RuleEngineService.getRuleEngineParameterRangeLimit(body)
      .then(async (response: any) => {
        const result = response?.data?.message[0];
        setRangeDetails(result[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if ((parameterNames.length > 0 && editing && parameterNames.length === props.editableCustomData[0].ParamCodes.split(',').length) || (parameterNames.length > 0 && !editing)) {
      getQueryBuilderData(parameterNames, parameterData)
      let qry = formatQuery(query, { format: 'sql', parseNumbers: true }), referenceQuery = formatQuery(query, { format: 'sql', parseNumbers: true });
      for (let i = 0; i < qry.length; i++) {
        if (qry[i] === 'D') {
          const actualName = parameterNames?.find(j => (j.ParamCode === qry.substring(i, i + 5)))?.ParamName;
          referenceQuery = referenceQuery.replace(qry.substring(i, i + 5), actualName)
        }
      }
      setCustomizedQuery(referenceQuery);
    }
  }, [parameterNames])

  useEffect(() => {
    if ((recipeNames.length > 0 && parameterNames.length > 0 && editing && parameterNames.length === props.editableCustomData[0].ParamCodes.split(',').length) || (parameterNames.length > 0 && !editing && recipeNames.length > 0 && parameterNames.length > 0)) {
      const rKeys: any = [], pKeys: any = [];
      parameterNames?.map(i => rKeys.push(i.MMParamKey));
      recipeNames?.map((i: any) => pKeys.push(i.RecipeKey));
      const data = { userId: loggedInUser, recipeKeys: pKeys.toString(), paramKeys: rKeys.toString(), token: token }
      fetchRangeDetails(data);
    }
  }, [recipeNames, parameterNames])

  const getQueryBuilderData = async (args: any, args2: any) => {
    const mPKeys: any = [];
    let finalResult;
    args?.map((i: any) => mPKeys.push(i.MMParamKey));
    const body = { machineParamKeys: mPKeys.toString(), token: token }
    await RuleEngineService.getParameterValueTypes(body)
      .then((response: any) => {
        const result = response?.data?.message[0];
        const fields: any = [];
        result[0]?.map((i: any) => {
          if (i.UIType === "Textbox") {
            if (i.DataType === "FLOAT") {
              const opList: any = [];

              operators?.filter((op: any) => { if (op.DataType === "FLOAT") { opList.push(op.RuleEngCondOperatorSymbol) } });
              const conName = args2.filter((p: any) => p.MMParamKey === i.MMParamKey);
              fields.push({
                "name": conName[0].ParamCode,
                "label": i.ParamName,
                "placeholder": 'Please enter',
                operators: defaultOperators.filter((op: any) => opList.includes(String(op.name).toLowerCase()) || op.name === '!=' || op.name === '=' || op.name === "between"),
                inputType: 'number',
                "groupNumber": 'group1',
                "valueSources": ['value', 'field'],
                "comparator": 'groupNumber',
              })
            } else {
              const opList: any = [];
              operators?.filter((op: any) => { if (op.DataType === "STRING") { opList.push(op.RuleEngCondOperatorSymbol) } });

              const conName = args2.filter((p: any) => p.MMParamKey === i.MMParamKey);
              fields.push({
                "name": conName[0].ParamCode,
                "label": i.ParamName,
                "placeholder": 'Please enter',
                operators: defaultOperators.filter(op => opList.includes(op.name) || op.name === '!=' || op.name === '='),
                inputType: 'text',
                "groupNumber": 'group1',
                "valueSources": ['value', 'field'],
                "comparator": 'groupNumber',
              })
            }
          }
          else {
            const paramsValueList: any = [], paramsNameValueList: any = [], paramLabels: any = []
            result[0]?.map((p: any) => {
              if (p.DataType === "Boolean" && i.UIType === "Dropdown") {
                if (i.MMParamKey === p.MMParamKey) {
                  paramsNameValueList.push({ "name": p.ParamValue, "label": p.ParamValueName });
                }
              } else {
                if (i.MMParamKey === p.MMParamKey) {
                  paramLabels.push({ label: p.ParamValueName, mmKey: p.MMParamKey })
                  paramsValueList.push(p.ParamValue);
                }
              }
            })
            const opList: any = [];
            operators?.filter((op: any) => {
              if (op.DataType === "STRING") {
                opList.push(op.RuleEngCondOperatorSymbol)
              }
            });
            const conName: any = parameterData.filter((p: any) => p.MMParamKey === i.MMParamKey);
            fields.push({
              "name": conName[0].ParamCode,
              "label": i.ParamName,
              values: i.DataType === "STRING" ? [{ "label": "Parameter name", data: paramsValueList }].map(({ label, data }) => ({
                label,
                options: data.map((s: any, ind: any) => ({ name: s, label: paramLabels[ind].label })
                ),
              })) : paramsNameValueList,
              valueEditorType: i.DataType === "STRING" ? 'select' : 'radio',
              operators: defaultOperators.filter(op => opList.includes(op.name) || op.name === '!='),
              "groupNumber": 'group1',
              "valueSources": ['value', 'field'],
              "comparator": 'groupNumber',
            })
          }
        })
        setQueryBuildData(fields);
      })
      .catch((err) => {
        console.log(err);
      });
    return finalResult;
  }

  const handleChangeNotificationNames = (id: any) => {
    let newSelected: any = [];
    if (props.customKey === '') {
      const selectedIndex = notificationNames.indexOf(id);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(notificationNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(notificationNames.slice(1));
      } else if (selectedIndex === notificationNames.length - 1) {
        newSelected = newSelected.concat(notificationNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          notificationNames.slice(0, selectedIndex),
          notificationNames.slice(selectedIndex + 1)
        );
      }
    }
    else {
      const selectedIndex = notificationNames.findIndex((t) => t.RuleEngNotifGrpKey === id.RuleEngNotifGrpKey);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(notificationNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(notificationNames.slice(1));
      } else if (selectedIndex === notificationNames.length - 1) {
        newSelected = newSelected.concat(notificationNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          notificationNames.slice(0, selectedIndex),
          notificationNames.slice(selectedIndex + 1)
        );
      }
    }
    setNotificationNames(newSelected);
  }

  const reset = async () => {
    props.setEditableCustomData([]);
    setCanCopy(false);
    setCopyingCustomKey('');
    props.setCustomKey('');
    setRuleName('');
    setRuleDesc('');
    setSeverityKey('');
    setModelsData([]);
    setMachineModelKey('');
    setDuration('');
    setNotificationGroupsData([]);
    setNotificationNames([]);
    setMachineNameData([]);
    setMachineNames([]);
    setRecipeData([]);
    setRecipeNames([]);
    setRecommendedNotes('');
    setCustomerKey('');
    setPresetRulesData([]);
    setRuleEnginePresetKey('');
    setSubject('');
    setNotifyType('P')
    setGroupNameData([]);
    setGroupKey('');
    setParameterData([]);
    setParameterNames([]);
    setQueryBuildData([]);
    setQuery(initialQuery);
    setAlertType("Y");
    setGroupKeyNames([]);
  }

  const options = {
    AD_SUCCESS: {
      message: `Custom alert ${props.customKey === "" ? 'created' : 'edited'} successfully!`,
      open: true,
      type: 'success'
    },
    AD_FAIL: {
      message: `Custom alert with the same name alredy exsist !`,
      open: true,
      type: 'remark'
    }
  };

  const createCustomAlert = () => {
    const mKeys: any = [], rKeys: any = [], nKeys: any = [];
    machineNames?.map(i => mKeys.push(i.MachineKey));
    recipeNames?.map(i => rKeys.push(i.RecipeKey));
    notificationNames?.map(i => nKeys.push(i.RuleEngNotifGrpKey));
    let fp: any = [];
    for (let i = 0; i < parameterNames.length; i++) {
      if (formatQuery(query, { format: 'sql', parseNumbers: true }).toString().includes(parameterNames[i].ParamCode)) {
        fp.push(parameterNames[i].ParamCode)
      }
    }
    fp = fp.filter((item: any,
      index: number) => fp.indexOf(item) === index);
    let formula = formatQuery(query, { format: 'sql', parseNumbers: true },);
    formula = formula;

    const result = groupKeyNames.map((item: any) => item.MachMdlParamGrpKey).join(',')
    const body = {
      userId: loggedInUser,
      customerKey: customerKey,
      ruleName: ruleName,
      ruleDesc: ruleDesc,
      condFormula: formula,
      jsonCond: formatQuery(query, 'json_without_ids'),
      MachMdlParamGrpKeys: result,
      paramCodes: fp.toString(),
      instantAlert: alertType,
      presetRuleKey: props.customKey ? props.customKey : null,
      machineModelKey: machineModelKey,
      machineKeys: mKeys ? mKeys.toString() : null,
      recipeKeys: rKeys ? rKeys.toString() : null,
      severityKey: severityKey,
      recommendedNotes: recommendedNotes,
      duration: duration ? duration : null,
      alertType: notifyType,
      notifiyGroups: nKeys.toString(),
      subject: subject,
      type: props.customKey !== '' ? 'Edit' : "Add",
      token: token
    }

    RuleEngineService.createRuleEngineCustomRule(body)
      .then((response: any) => {
        if (response.status === 200) {
          reset();
          props.setCurrTab(1);
          const result = response?.data?.message[0];
          const checkIfExsist = result[0];
          const isError = checkIfExsist[0];
          if (isError.Result === 'Error') {
            props.setSnackbarOpts(options.AD_FAIL);
          }
          else {
            props.setSnackbarOpts(options.AD_SUCCESS);
          }
          props.drawerClose();
          props.fetchCustomAlerts();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const check = (object: any) => {
    for (const value of Object.values(object)) {
      if (value === "" || (typeof value === "object" && check(value))) {
        return true;
      }
    }

    return false;
  }

  const yesDisabled = (
    check(query) ||
    ruleName === '' ||
    ruleDesc === '' ||
    recommendedNotes === '' ||
    customerKey === '' ||
    machineModelKey === '' ||
    parameterNames.length === 0 ||
    severityKey === '' ||
    recommendedNotes === '' ||
    query.rules.length === 0) ? true : false;
  let optionalDisabled: boolean;
  if (notifyType === "P") {
    optionalDisabled = false;
  }

  else {
    if (subject === '' || notificationNames.length === 0) {
      optionalDisabled = true
    }
    else {
      optionalDisabled = false;
    }

  }

  let instantAlertCheck: boolean;
  if (alertType === "N") {
    if (duration === '') {
      instantAlertCheck = true;
    } else { instantAlertCheck = false }
  } else {
    instantAlertCheck = false;
  }

  const DropDownIcon = (prop: any) => (
    <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
  );

  const renderButtons = () => {
    return (
      <div className={'btns noWrap'} style={{ display: "flex", margin: "10px 0", justifyContent: "flex-end", width: "100%" }}>
        <ACGButton
          name={'Cancel'}
          handleClick={props.drawerClose}
          secondary={true}
          className={'first-btn'}
          style={{ height: '40px', marginRight: '10px' }}
          type="button"
          variant="contained"
        />
        <ACGButton
          name={'Submit'}
          handleClick={() => { createCustomAlert() }}
          type="submit"
          className="second-btn"
          style={{ height: '40px', fontWeight: '500' }}
          variant="contained"
          disabled={yesDisabled || optionalDisabled || instantAlertCheck ? true : false}
          isLoading = {isLoading}
        />
      </div>
    );
  };

  return (
    <Container className="createReportt">
      <Box>
        <div className='formwrap all-labelcls'>
          <FormControl fullWidth>
            <Grid container spacing={2}>
              {props.customKey === "" ?
                <>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <Label label="Copy Exsisting Rule" className={'CMlabelDiv'} />
                      <DropDown
                        className="inputDiv"
                        name="dropdownModel"
                        items={copyingRules}
                        placeHolder="Select rule"
                        disabled={!canCopy ? false : true}
                        value={copyingCustomKey}
                        onChange={(e: any) => {
                          props.setEditableCustomData([]);
                          setCopyingCustomKey(e.target.value)
                          props.fetchCustomAlertDetails(e.target.value)
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={2} >
                    {console.log(canCopy,"<===")}
                    {!canCopy ?
                    <Button style={{ textTransform: "capitalize", marginTop: 34 }}
                      onClick={() => setCanCopy(true)}
                      disabled={props.editableCustomData.length === 0  ? true : false}
                    >Copy</Button> :
                    <Button style={{ textTransform: "capitalize", marginTop: 34 }}
                      onClick={() => { reset(); setCopyingCustomKey(''); props.setEditableCustomData([])}}
                      disabled={copyingCustomKey === "" ? true : false}
                    >Clear</Button>}
                  </Grid>
                  <Grid item xs={4}>
                  </Grid>
                </>
                : null}

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Label label="Rule Name &nbsp; *" className={'CMlabelDiv'} />
                  <TextInput id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    sx={{ marginBottom: "20px", height: '20px' }}
                    value={ruleName}
                    onChange={(e) => setRuleName(e.target.value)}
                    maxLength={50}
                    placeholder='Enter rule name'
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Label label="Rule Description &nbsp; *" className={'CMlabelDiv'} />
                  <Box
                    className="multilineheight"
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 0, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextInput
                      fullWidth
                      placeholder='Enter rule description'
                      id="outlined-basic"
                      className="multilinetxt"
                      multiline
                      maxRows={4}
                      sx={{ margin: '0px', padding: '2px' }}
                      value={ruleDesc}
                      onChange={(e) => setRuleDesc(e.target.value)}
                    // size='500'
                    />
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Label label="Customer Name &nbsp; *" className={'CMlabelDiv'} />
                  <DropDown
                    className="inputDiv"
                    name="dropdownModel"
                    items={props.customersData}
                    placeHolder="Select customer"
                    value={customerKey}
                    disabled={props.customKey === '' ? false : true}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Label label="Model &nbsp; *" className={'CMlabelDiv'} />
                  <DropDown
                    className="inputDiv"
                    name="dropdownModel"
                    items={modelsData}
                    placeHolder="Select model"
                    value={machineModelKey}
                    onChange={handleChangeModel}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <div className='labelfixed mbt'>
                  <FormControl fullWidth>
                    <Label label="Machine Name (optional)" className={'CMlabelDiv'} />
                    <FormControl sx={{ width: '100%' }}>
                      {machineNames?.length > 0 ? null : (
                        <InputLabel
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            paddingTop: '0px',
                            marginBottom: '0px',
                            paddingLeft: '12px',
                            lineHeight: '1',
                            minHeight: '20px',
                            color: '#b1c2df',
                            opacity: '0.7'
                          }}
                          id="demo-multiple-checkbox-label"
                        >
                          Select Machines
                        </InputLabel>
                      )}
                      <Select
                        IconComponent={DropDownIcon}
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        className="multibox2"
                        label=""
                        multiple
                        value={machineNames}
                        sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                        placeholder='Please select machines'
                        // input={<OutlinedInput />}
                        renderValue={(selected) => selected.map((x: any) => x.LineMachineName).join(', ')}
                        MenuProps={MenuProps}
                        onBlur={() => getReciepeList(customerKey, machineModelKey, machineNames)}

                      >
                        {machineNameData?.map((i: any) => (
                          <MenuItem value={i} onClick={() => handleChangeMachineNames(i)}>
                            <Checkbox
                              checked={
                                machineNames.findIndex((item: any) => item.MachineKey === i.MachineKey) >= 0
                              }

                            />
                            <ListItemText primary={i.LineMachineName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={4}>
                <div className='labelfixed mbt'>
                  <FormControl fullWidth>
                    <Label label="Recipe Name (optional)" className={'CMlabelDiv'} />
                    <FormControl sx={{ width: '100%' }}>
                      {recipeNames?.length > 0 ? null : (
                        <InputLabel
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            paddingTop: '0px',
                            marginBottom: '0px',
                            paddingLeft: '12px',
                            color: '#b1c2df',
                            lineHeight: '1',
                            minHeight: '20px',
                            opacity: '0.7'
                          }}
                          id="demo-multiple-checkbox-label"
                        >
                          Select Recipes
                        </InputLabel>
                      )}
                      <Select
                        IconComponent={DropDownIcon}
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        sx={{ marginBottom: "20px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                        multiple
                        value={recipeNames}
                        input={<OutlinedInput />}
                        renderValue={(selected) => selected.map((x: any) => x.RecipeName).join(', ')}
                        MenuProps={MenuProps}
                      >
                        {recipeData?.map((i: any) => (
                          <MenuItem value={i} onClick={() => handleChangeRecipeNames(i)}>
                            <Checkbox
                              checked={
                                recipeNames.findIndex((item: any) => item.RecipeKey === i.RecipeKey) >= 0
                              }
                            />
                            <ListItemText primary={i.RecipeName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Label label="Alert Type &nbsp; *" className={'CMlabelDiv'} />
                  <RadioGroup
                    row
                    aria-labelledby="demo-alerttype-row-radio-buttons-group-label"
                    name="row-alerttype-radio-buttons-group" className='radiolabls fontSizeSpan'

                  >
                    <FormControlLabel style={{ fontSize: '12px' }} value="Y" control={<Radio onChange={handleChangeAlertType} checked={alertType === "Y" ? true : false} />} label="Instant Alert" />
                    <FormControlLabel value="N" control={<Radio onChange={handleChangeAlertType} checked={alertType === "N" ? true : false} />} label="Non Instant Alert" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {alertType === "N" ?
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Label label="Time Duration &nbsp; *" className={'CMlabelDiv'} />
                      <Tooltip title={<Typography fontSize={12} className='tooltiptxt'>If the created condition is true more than once in a span of time selected in the time duration field, then alert will be triggered</Typography>} arrow>
                        <HelpIcon sx={{ justifyContent: 'center', color: '#ffffff', marginLeft: "2px" }} />
                      </Tooltip>
                    </div>
                    <DropDown
                      className="inputDiv"
                      name="dropdownModel"
                      items={durationData}
                      placeHolder="Select duration"
                      value={duration}
                      onChange={handleChangeDuration}
                    />
                  </FormControl>
                </Grid>
                : null
              }

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Label label="Severity &nbsp; *" className={'CMlabelDiv'} />
                    <Tooltip title={<Typography fontSize={12} className='tooltiptxt'>If condition is continuously true for a period of time -
                      Critical Alerts will be triggered once in every 5 minutes.
                      Major Alerts will be triggered once in every 10 minutes.
                      Minor Alerts will be triggered once in every 15 minutes.
                    </Typography>} arrow>
                      <HelpIcon sx={{ justifyContent: 'center', color: '#ffffff', marginLeft: "2px" }} />
                    </Tooltip>
                  </div>

                  <DropDown
                    className="inputDiv"
                    name="dropdownModel"
                    items={severityData}
                    placeHolder="Select severity"
                    value={severityKey}
                    style={{ marginBottom: "10px" }}
                    onChange={handleChangeSeverity}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Label label="Notification Type &nbsp; *" className={'CMlabelDiv'} />
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group" className='radiolabls fontSizeSpan'

                  >
                    <FormControlLabel value="P" control={<Radio onChange={handleChangeNotifyType} checked={notifyType === 'P' ? true : false} />} label="Portal" />
                    {/* <FormControlLabel value="M" control={<Radio onChange={handleChangeNotifyType} checked={notifyType === 'M' ? true : false} />} label="Email" /> */}
                    <FormControlLabel value="MP" control={<Radio onChange={handleChangeNotifyType} checked={notifyType === 'MP' ? true : false} />} label="Portal & Email" />

                  </RadioGroup>

                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <div className='labelfixed mbt'>
                  <FormControl fullWidth>
                    <Label label="Notification Groups &nbsp; *" className={'CMlabelDiv'} />
                    <FormControl sx={{ width: '100%' }}>
                      {notificationNames?.length > 0 ? null : (
                        <InputLabel
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            paddingTop: '0px',
                            marginBottom: '0px',
                            paddingLeft: '12px',
                            color: '#b1c2df',
                            lineHeight: '1',
                            minHeight: '20px',
                            opacity: '0.7'
                          }}
                          id="demo-multiple-checkbox-label"
                        >
                          Select notification groups
                        </InputLabel>
                      )}
                      <Select
                        IconComponent={DropDownIcon}
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        sx={{ marginBottom: "20px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                        multiple
                        value={notificationNames}
                        input={<OutlinedInput />}
                        renderValue={(selected) => selected.map((x: any) => x.RuleEngNotifGroupName).join(', ')}
                        MenuProps={MenuProps}
                      >
                        {notificationGroupsData?.map((i: any) => (
                          <MenuItem value={i} onClick={() => handleChangeNotificationNames(i)}>
                            <Checkbox
                              checked={
                                notificationNames.findIndex((item: any) => item.RuleEngNotifGrpKey === i.RuleEngNotifGrpKey) >= 0
                              }
                            />
                            <ListItemText primary={i.RuleEngNotifGroupName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormControl>
                </div>
              </Grid>
              {notifyType !== "P" ?
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <Label label="Mail Subject &nbsp; *" className={'CMlabelDiv'} />
                    <TextInput
                      fullWidth
                      placeholder='Enter email subject'
                      variant="outlined"
                      sx={{ marginBottom: "0px", marginTop: "0px" }}

                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                :
                null
              }
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Label label="Recommendation &nbsp; *" className={'CMlabelDiv multilineheight'} />
                  <Box
                    className='multilineheight'
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 0, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextInput
                      fullWidth
                      placeholder='Enter recommendation'
                      className="multilinetxt"
                      variant="outlined"
                      maxRows={4}
                      sx={{ margin: '0px', padding: '2px' }}
                      multiline
                      value={recommendedNotes}
                      onChange={(e) => setRecommendedNotes(e.target.value)}

                    // size='500'

                    />
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <div className='labelfixed mbt'>
                  <FormControl fullWidth>
                    <Label label="Group Name (optional)" className={'CMlabelDiv'} />
                    <FormControl sx={{ width: '100%' }}>
                      {groupKeyNames?.length > 0 ? null : (
                        <InputLabel
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            paddingTop: '0px',
                            marginBottom: '0px',
                            paddingLeft: '12px',
                            color: '#b1c2df',
                            lineHeight: '1',
                            minHeight: '20px',
                            opacity: '0.7'
                          }}
                          id="demo-multiple-checkbox-label"
                        >
                          Select group name
                        </InputLabel>
                      )}
                      <Select
                        IconComponent={DropDownIcon}
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        sx={{ marginBottom: "20px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                        multiple
                        value={groupKeyNames}
                        input={<OutlinedInput />}
                        renderValue={(selected) => selected.map((x) => x.GroupName).join(', ')}
                        MenuProps={MenuProps}
                      >
                        {groupNameData?.map((i: any) => (
                          <MenuItem value={i} onClick={() => handleChangeGroupName(i)}>
                            <Checkbox
                              checked={
                                groupKeyNames.findIndex(item => item.MachMdlParamGrpKey === i.MachMdlParamGrpKey) >= 0
                              }
                            />
                            <ListItemText primary={i.GroupName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Label label="Parameters Name &nbsp; *" className={'CMlabelDiv'} />
                  <FormControl sx={{ width: '100%' }}>
                    {parameterData?.length > 0 ? null : (
                      <InputLabel
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          paddingTop: '7px',
                          marginBottom: '0px',
                          paddingLeft: '12px',
                          color: '#b1c2df',
                          lineHeight: '1',
                          minHeight: '20px',
                          opacity: '0.7'
                        }}
                        id="demo-multiple-checkbox-label"
                      >
                        Select parameters
                      </InputLabel>
                    )}
                    <Autocomplete
                      sx={{ marginBottom: "20px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                      multiple
                      limitTags={2}
                      key="parameter-code"
                      id="checkboxes-tags-demo"
                      className='alldropbox extracls'
                      options={parameterData}
                      disableCloseOnSelect
                      getOptionLabel={(option: any) => option.ParamName}
                      isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                      value={parameterNames}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => {
                          return (
                            <Chip
                              label={option.ParamName}
                              {...getTagProps({ index })}
                              onDelete={() => { handleChangeParameterName(option, parameterData); setEditing(false); clean(Object.values(query), option?.ParamCode) }}
                            />
                          )
                        })
                      }
                      renderOption={(props, option, { selected }, index) => (
                        <li {...props} key={option.ParamCode}
                          onClick={() => { handleChangeParameterName(option, parameterData); setEditing(false); }}

                        >
                          <Checkbox

                            // key={index}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            onChange={() => { handleChangeParameterName(option, parameterData); setEditing(false); }}
                            checked={
                              parameterNames.findIndex(item => item.MMParamKey === option.MMParamKey) >= 0
                            }

                          />
                          {option.ParamName}
                        </li>
                      )}
                      // style={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField {...params} />
                      )}
                    />
                  </FormControl>
                </FormControl>
              </Grid>

              <Grid item xs={8}>
                <FormControl fullWidth>
                  <label className={'CMlabelDiv label-style'}>Create Rule &nbsp;*
                    {recipeNames.length === 1 && rangeDetails.length > 0 ?
                      <Tooltip title={<Typography fontSize={12} className='tooltiptxt'>
                        {
                          rangeDetails?.map((i: any) => {
                            return (
                              <>
                                <Typography>{i.ParamName}</Typography>
                                <ul>
                                  <li>Golden Batch No: {i.GoldenBatchNo}</li>
                                  <li>Upper Limit: {i.UpperLimit}</li>
                                  <li>Lower Limit: {i.LowerLimit}</li>
                                </ul>
                              </>
                            )

                          })
                        }
                      </Typography>} arrow>
                        <HelpIcon sx={{ justifyContent: 'center', color: '#ffffff', marginLeft: "2px" }} />
                      </Tooltip> : null}</label>
                  <div className='wrapper-cm cardGroupList'>
                    <ThemeProvider theme={muiTheme}>
                      <QueryBuilderMaterial>
                        <QueryBuilder
                          key={query}
                          fields={queryBuildData}
                          query={query}
                          onQueryChange={(q) => { setQuery(q); }}
                          autoSelectField={false}
                          autoSelectOperator={false}
                          resetOnFieldChange={false}
                          showCombinatorsBetweenRules={true}
                          resetOnOperatorChange

                        />
                      </QueryBuilderMaterial>
                    </ThemeProvider>

                  </div>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Label label="Rule Engine Formula &nbsp; *" className={'CMlabelDiv'} />
                  <Label label={query.rules.length === 0 ? "Please enter the conditions" : customizedQuery} />
                </FormControl>
              </Grid>
            </Grid>

          </FormControl>
        </div>
      </Box>
      <Grid style={{ display: "flex", marginTop: 20 }}>
        {renderButtons()}
      </Grid>
    </Container>
  );
}